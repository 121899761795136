<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-sm-12" v-html="$t('onu_registration.variable_block')" style="margin-bottom: 15px;"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :options="devicesList"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onu_registration.unregistered_ont') }}</label>
              <v-select class="style-chooser" v-model="ont" label="name" :options="unregisteredOnts"
                        :disabled="unregisteredOnts.length === 0"></v-select>
            </div>
          </div>

        </div>
        <Preloader v-if="variables === null && loadingVariables"></Preloader>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-mini">
        <div class="row">
          <div class="col-sm-12 col-lg-12 col-md-12" v-html="$t('onu_registration.parameters_descriptions')">

          </div>
          <div class="col-sm-12 col-lg-3 col-xl-3 col-md-12">
            <div class="form-group">
              <label class="mb-0"> &nbsp;</label>
              <button @click="addNewParameter()" class="btn btn-default btn-block" style="vertical-align: top;  ">
                {{ $t('onu_registration.add_parameter') }}
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-lg-3 col-xl-3 col-md-12">
            <div class="form-group">
              <label> &nbsp;</label>
              <button @click="saveParameters(parameters)" class="btn btn-success btn-block"
                      style="vertical-align: top;  ">{{ $t('onu_registration.save_parameters') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <draggable v-model="parameters" item-key="order" handle=".handle">
      <div class="row" v-for="(param, num) in parameters" :key="num" style="border: 1px dotted gray; margin-top: 5px">
        <div class="col-11" style="padding: 15px">
          <div class="row">
            <div class="col-12 col-mini col-sm-6 col-lg-3 col-xl-2 col-md-6">
              <div class="form-group">
                <label>{{ $t('onu_registration.key') }}</label>
                <input name="" v-model="param.key" class="form-control">
                <small>{{ $t('onu_registration.key_reg_description') }}</small>
              </div>
            </div>
            <div class="col-12 col-mini col-sm-6 col-lg-3 col-xl-3 col-md-6">
              <div class="form-group">
                <label>{{ $t('onu_registration.visible_name') }}</label>
                <input name="" v-model="param.name" class="form-control">
              </div>
            </div>
            <div class="col-sm-3 col-lg-2 col-xl-2 col-md-3">
              <div class="form-group">
                <label>{{ $t('onu_registration.is_required') }}</label>
                <input type="checkbox" v-model="param.required" class="form-control"
                       style="margin: 2px; width: 28px; height: 28px; border: 1px solid gray">
              </div>
            </div>
            <div class="col-sm-9 col-lg-4 col-xl-3 col-md-6">
              <div class="form-group">
                <label>{{ $t('onu_registration.parameter_type') }}</label>
                <v-select class="style-chooser" v-model="param.type" :reduce="sel => sel.key" label="name"
                          :options="parameterProperties.types"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'input_string'">
              <div>
                <div class="form-group">
                  <label>{{ $t('onu_registration.default_value') }}</label>
                  <input class="form-control" v-model="param.default_value"
                         :placeholder="$t('onu_registration.parameter_can_be_empty')">
                </div>
                <small v-html="$t('onu_registration.input_string_description')"></small>
              </div>
              <div>
                <div class="form-group">
                  <label>{{ $t('onu_registration.regular_expr') }}</label>
                  <input class="form-control" v-model="param.regular_expr"
                         :placeholder="'^[0-9]{1,14}$'">
                </div>
                <small v-html="$t('onu_registration.input_regular_expression')"></small>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'input_variable'">
              <div class="form-group">
                <label>{{ $t('onu_registration.source_param_key') }}</label>
                <v-select class="style-chooser" :create-option="key => ({ key: key, type: 'string' })"
                          v-model="param.source_parameter_key" label="key" :reduce="sel => sel.key"
                          :options="getVariableObjectAsArray(variables).filter(e => {return e.type === 'string' || e.type === 'number' })"></v-select>
              </div>
              <div class="form-group">
                <div>
                  <label>{{ $t('default_value') }}</label>
                  <input class="form-control" v-model="param.default_value"
                         :placeholder="$t('onu_registration.parameter_can_be_empty')">
                </div>
                <small v-html="$t('onu_registration.input_variable_description')"></small>
              </div>
              <div>
                <div class="form-group">
                  <label>{{ $t('onu_registration.regular_expr') }}</label>
                  <input class="form-control" v-model="param.regular_expr"
                         :placeholder="'^[0-9]{1,14}$'">
                </div>
                <small v-html="$t('onu_registration.input_regular_expression')"></small>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'select_from_variable'">
              <div class="form-group">
                <label>{{ $t('onu_registration.source_param_key') }}</label>
                <v-select class="style-chooser" :create-option="key => ({ key: key, type: 'array' })"
                          v-model="param.source_parameter_key" label="key" :reduce="sel => sel.key"
                          :options="getVariableObjectAsArray(variables).filter(e => {return e.type === 'array'})"></v-select>
              </div>
              <div
                  v-if="param.source_parameter_key !== '' && getVariableObjectAsArray(variables).filter(e => {return e.key === param.source_parameter_key}).length !== 0">
                <label>{{ $t('onu_registration.variants') }}</label>
                <li v-for="(name, key) in getVariableObjectAsArray(variables).filter(e => {return e.key === param.source_parameter_key})[0].value"
                    :key="key">
                  {{ name }}
                </li>
              </div>
              <small v-html="$t('onu_registration.select_from_variable_description')"></small>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 col-md-6" v-if="param.type === 'select_from_predefined'">
              <div class="form-group">
                <label>{{ $t('onu_registration.select_from_variables_list') }}</label>
                <textarea v-model="param.variants_list" class="form-control" style="min-height: 200px"></textarea>
              </div>
              <small v-html="$t('onu_registration.select_from_predefined_description')"></small>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-5 col-md-6">
              <div class="form-group">
                <label>{{ $t('onu_registration.visible_if') }}</label>
                <textarea name="" v-model="param.visible_if" class="form-control"></textarea>
                <small>{{ $t('onu_registration.visible_if_description') }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-mini col-lg-1 col-xl-1 col-md-1 " style="text-align: right">
          <button class="btn btn-outline-info" style="margin: 5px"><i class="fa fa-align-justify handle"> </i></button>
          <button class="btn btn-danger" @click="deleteParameterByIndex(num)" style="margin: 5px"><i
              class="fa fa-trash"></i></button>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>

import draggable from "vuedraggable";
import Preloader from "@/components/Preloader.vue";

export default {
  components: {Preloader, draggable},
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    vendorName: {
      type: String,
      required: true,
    }
  },
  async mounted() {
    this.devicesList = await this.loadDevicesForSelect()
    this.parameters = await this.loadParameters()
  },
  data() {
    return {
      variables: null,
      unregisteredOnts: [],
      devicesList: [],
      device: null,
      ont: null,
      parameters: [],
      loadingVariables: false,

      parameterProperties: {
        types: [
          {key: 'select_from_predefined', name: this.$t('onu_registration.select_from_predefined')},
          {key: 'select_from_variable', name: this.$t('onu_registration.select_from_variable')},
          {key: 'input_variable', name: this.$t('onu_registration.input_variable')},
          {key: 'input_string', name: this.$t('onu_registration.input_string')},
        ],
      }
    }
  },
  methods: {
    addNewParameter() {
      this.parameters.push({
        visible_if: '',
        order: this.parameters.length + 1,
        key: '',
        name: '',
        required: false,
        type: 'input_variable',
        value: '',
        variants_list: '',
        default_value: '',
        source_parameter_key: '',
        regular_expr: '',
      })
    },
    async loadParameters() {
      var parameters = []
      await this.$api.get(this.apiComponentName + '/parameters').then(r => {
        r.data.forEach(e => {
          if (!e.regular_expr) {
            e.regular_expr = ''
          }
          parameters.push(e)
        })

      })
      return parameters
    },
    async saveParameters(parameters) {
      this.loading = true
      let error = false
      let parameterKeys = []
      this.parameters.forEach((e) => {
        if (parameterKeys.indexOf(e.key) !== -1) {
          this.$noty.warning(this.$t('onu_registration.duplicate_key', {key: e.key}))
          error = true
        }
        try {
          new RegExp(e.regular_expr)
        } catch (e) {
          this.$noty.warning(this.$t('onu_registration.incorrect_regular_expression', {key: e.key}))
          error = true
        }
        if (e.visible_if !== '') {
          try {
            eval(e.visible_if);
          } catch (e) {
            console.log(e.message);
            console.log(e.name);
            console.log(e.fileName);
            console.log(e.lineNumber);
            console.log(e.columnNumber);
            console.log(e.stack);
            if (e.message.includes('Unexpected')) {
              this.$noty.warning(this.$t('onu_registration.incorrect_visible_if_condition', {key: e.key}))
              error = true
            }
          }
        }
        parameterKeys.push(e.key)
      })
      if (error) {
        return false
      }
      if (parameters.length === 0 && !confirm(this.$t('parameters_is_empty_are_you_sure'))) {
        return
      }
      await this.$api.put(this.apiComponentName + '/parameters', parameters).then(() => {
        this.$noty.success(this.$t('onu_registration.parameters_success_saved'))
      })
      this.loading = false
    },
    deleteParameterByIndex(index) {
      if (confirm(this.$t('onu_registration.are_you_sure_to_delete'))) {
        this.parameters = this.parameters.filter((v, k) => {
          return k !== index
        })
        this.$noty.info(this.$t('onu_registration.element_success_deleted'))
      }
    },

    async reRenderAll() {
      this.loading = true
      this.parameters = await this.loadParameters()
      if (this.device !== null && this.ont !== null) {
        this.variables = await this.loadVariables()
      } else {
        this.variables = null
      }
      this.loading = false
    },
    async loadDevicesForSelect() {
      var data = []
      data.push({
        displayName: "Mock device",
        name: "Mock device",
        id: -1,
      })
      await this.$api.get('/device?limit=99999&query=OLT&ascending=name').then(r => {
        r.data.forEach(d => {
          if(!d.enabled) return;
          if (d.name.trim() === '') {
            d.name = this.$t('no_name')
          }
          if (d.model.vendor !== this.vendorName || d.model.type !== 'OLT') {
            return
          }
          d.displayName = `${d.ip} - ${d.name}`

          data.push({
            displayName: d.displayName,
            name: d.name,
            id: d.id
          })
        })
      })
      return data
    },
    async loadOntsForSelect(deviceId) {
      var variants = []
      variants.push({
        name: "Mock GPON ONU",
        key: 'mock_gpon_onu',
      })
      variants.push({
        name: "Mock EPON ONU",
        key: 'mock_epon_onu',
      })
      await this.$api.get(this.apiComponentName + '/unregistered/' + deviceId).then(r => {
        r.data.forEach(elem => {
          let ident = ''
          if(elem.serial) ident = elem.serial
          if(elem.mac_address) ident = elem.mac_address

          variants.push({
            key: ident,
            name: elem.interface.name + ' - ' + ident,
          })
        })
      }).catch(e => {
        console.log(e)
      })
      return variants
    },
    async loadVariables() {
      var variables = []
      this.loadingVariables = true
      if (this.ont === null) {
        await this.$api.post(this.apiComponentName + '/variables/' + this.device.id).then(r => {
          variables = r.data
        })
      } else {
        await this.$api.post(this.apiComponentName + '/variables/' + this.device.id + '/' + this.ont.key).then(r => {
          variables = r.data
        })
      }
      this.loadingVariables = false
      return variables
    },
    getVariableObjectAsArray(object) {
      let fieldBuilder = function (object, rootKey = '') {
        var elements = []
        if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
          for (const [key, value] of Object.entries(object)) {
            let keys = rootKey + '.' + key
            fieldBuilder(value, keys).forEach(e => {
              elements.push(e)
            })
          }
        } else {
          elements.push({
            key: rootKey,
            value: object
          })
        }
        return elements
      }

      return fieldBuilder(object).map(e => {
        let type = typeof e.value
        if (Array.isArray(e.value)) {
          type = 'array'
        }
        return {
          key: e.key.substring(1),
          value: e.value,
          type: type
        }
      })
    },
  },
  watch: {
    parameters: {
      handler() {
        let order = 0
        this.parameters.forEach((val, key) => {
          this.parameters[key].order = order++
        })
      },
      deep: true,
    },
    ont: {
      handler() {
        this.reRenderAll()
      },
      deep: true,
    },
    device: {
      async handler(n) {
        if (n !== null) {
          this.unregisteredOnts = [{
            key: 0,
            name: this.$t('loading'),
          }]
          this.ont = null
          this.unregisteredOnts = await this.loadOntsForSelect(n.id)
        } else {
          this.unregisteredOnts = []
          this.ont = null
        }
        await this.reRenderAll()
      },
      deep: true,
    },
  },
};
</script>
