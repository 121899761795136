<template>
  <div class="container-fluid">
    <div class="row" v-if="loading && error === ''">
      <div class="col-12 col-mini">
        <Preloader/>
      </div>
    </div>
    <div class="row" v-if="error !== ''">
      <div class="col-12 col-mini">
        <SomethingWrong/>
        {{ error }}
      </div>
    </div>
    <div class="row" v-if="!loading && error === ''">
      <div class="col-12 col-mini col-sm-12 col-lg-5 col-xl-4 col-md-12">
        <Card :name="$t('personal_info')" :show="true">

          <div style="margin-bottom: 10px; margin-top: 5px">
            <div style="font-size: 18px">
              {{ $t('your_login') }}: <b>{{ data.login }}</b><br>
            </div>
            {{ $t('id') }}: <b>{{ data.id }}</b><br>
            {{ $t('your_role') }}: <b v-if="typeof data.role !== 'undefined'">{{ data.role.name }}</b><br>
          </div>
          <hr>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{$t('language')}}</label>
            <div class="col-sm-9">
              <v-select class="style-chooser"
                        v-model="data.language" label="value" :options="locales" :reduce="locale => locale.key" :hide-selected="true"
                        :clearable="false"
                        :close-on-select="true"
                        :filterable="false"
                        :searchable="false"
              ></v-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('name') }}</label>
            <div class="col-sm-9">
              <input type="text" v-model="data.name"
                     class="form-control" :placeholder="$t('user_name')">
            </div>
          </div>
          <div class="form-group row"> 
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('new_password') }}</label> 
            <div class="col-10 col-sm-8"> 
              <input :type="showPassword ? 
                'text' : 
                'password'"  
                class="form-control" 
                v-model="data.password" 
                @input="validateForm" 
                placeholder="*************"
                style="vertical-align: bottom"/>  
                <small>{{ $t('password_instruction') }}</small>
            </div> 
            <div class="col-2 col-sm-1 toggle">
              <button class="btn btn-block toggle" @click="toggleShow"> 
                <span class="icon is-small is-right"> 
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i> 
                </span> 
              </button> 
            </div>
          </div>    
          <div class="form-group row"> 
              <label class="col-sm-3 text-right control-label col-form-label">{{ $t('confirm_password') }}</label>
              <div class="col-sm-9">
                <input :type="showPassword ? 
                  'text' : 
                  'password'"
                  class="form-control" 
                  v-bind:class="{ '': isActive, 'invalid': hasError }"
                  v-model="confirmPassword" 
                  @input="validateForm" 
                  placeholder="*************"
                  style="vertical-align: bottom"/> 
                <p v-if="errorMessage" class="form-control__msg">{{ errorMessage }}</p>
              </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 text-right control-label col-form-label">{{ $t('twofa') }}</label>
            <div class="col-sm-9">
              <button v-if="!data.is_twofa" class="btn btn-default" @click="open2faModal">
                {{ $t('twofa_connect') }}
              </button>
              <button v-if="data.is_twofa" class="btn btn-default" @click="disconnect2FA">
                {{ $t('twofa_disconnect') }}
              </button>
            </div>
          </div>

          <div class="row">
            <hr style="margin-top: 10px; width: 100%">
            <div class="col-12 col-mini">
              <button class="btn btn-default" @click="updateUser" :disabled="savingInProcess" v-bind:class="{ 'btn btn-default': isActive, 'disabled': hasError }">
                <i class="fa fa-save" style="padding-right: 4px; padding-top: 2px; padding-bottom: 2px; font-size: 16px"></i>
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </Card>
        <AccountSettings :settings="data.settings" :user-id="'self'" />
      </div>
      <div class="col-12 col-mini col-sm-12 col-lg-7 col-xl-8 col-md-12" v-if="userId !== 0">
        <Card :name="$t('notifications_configuration')" :show="true" v-if="
        $auth.isPermitted('notifications_configure_self_contacts') &&
      $config.isComponentEnabled('notifications') &&
      $config.isComponentEnabled('events')
">
          <UserSourcesComponent :user-id="'self'"></UserSourcesComponent>
        </Card>
        <Card :name="$t('strict_by_ip_access')" :show="true"  v-if="$auth.isPermitted('user_management_config_strict_ip')">
          <StrictByIp :settings="data.settings" :user-id="userId"></StrictByIp>
        </Card>
        <Card :name="$t('active_sessions')" :show="true" v-if="typeof data.active_sessions !== 'undefined'">
          <div v-cloak>
            <v-client-table v-model="data.active_sessions" :columns="table.columns" :options="table.options">
              <template v-slot:actions="{ row }">
                <div>
                  <button class="btn btn-default" @click="closeSession(row.id)"><i class="mdi mdi-exit-to-app"></i>
                    {{ $t('close_session') }}
                  </button>
                </div>
              </template>
              <template v-slot:device="{ row }">
                <div v-if="row.device !== null  && row.device.device === 'desktop'">
                  <i class="mdi mdi-desktop-tower"></i> <b>{{ row.device.os_info.name }}
                  {{ row.device.os_info.version }}</b>
                  <br>
                  {{ row.device.client.name }} <i>{{ row.device.client.version }}</i>
                </div>
              </template>
            </v-client-table>
          </div>
        </Card>
      </div>
    </div>

    <ModalForm ref="twofaModal" :modal-width="500" :title="$t('twofa_connecting')" >
      <template v-slot:content >
        <preloader v-if="twofaQrLoading"></preloader>
        <div style="padding-top: 10px; padding-left: 10px; padding-right: 10px" v-html="$t('twofa_instruction')">

        </div>
        <div v-if="twofaData && !twofaQrLoading" style="text-align: center; padding-top: 15px">
          <div v-html="twofaData.qr" style="margin-bottom: 5px" />
          <p>{{ $t('twofa_manual') }} <b id="twofaCode" ref="twofaCode">{{ twofaData.code }}</b> <span @click="copy" style="cursor: pointer;color: blue;" ><i class="fa fa-copy"></i> {{ $t('copy') }}</span></p>
        </div>

        <div v-if="twofaData && !twofaQrLoading" class="row p-b-30" style="text-align: center;align-items: center;justify-content: center;">
          <div class="col-6 col-mini">
            <div class="input-group mb-3">
              <input type="text"
                    v-model="twofaPin"
                     class="form-control form-control-lg" :placeholder="$t('twofa_pin')" aria-label="twofa_pin" aria-describedby="basic-addon1" required="">
            </div>
          </div>
        </div>
        <div ref="reference"></div>
      </template>
      <template v-slot:footer>
        <button @click="$refs.twofaModal.hide()" class="button-close"><i class="fa fa-window-close"></i>  {{ $t('cancel') }}
        </button>
        <button @click="twofaConnect" class="button-save"><i class="fa fa-save"></i>  {{ $t('twofa_connect_submit') }}
        </button>
      </template>
    </ModalForm>
  </div>
</template>


<script>
import Preloader from "@/components/Preloader.vue";
import ModalForm from "@/components/ModalForm.vue";import SomethingWrong from "@/components/SomethingWrong";
import Card from "@/components/Card"
import UserSourcesComponent from "./Notifications/UserContactsConfiguration";
import AccountSettings from "../components/Blocks/AccountSettings";
import StrictByIp from "@/components/Blocks/StrictByIp.vue";

export default {
  components: {StrictByIp, AccountSettings, UserSourcesComponent, Card, SomethingWrong, Preloader, ModalForm},
  data() {
    return {
      message: '',
      twofaData: null,
      twofaPin: null,
      userId: 0,
      data: {},
      groups: [],
      savingInProcess: false,
      loading: true,
      showPassword: false,
      confirmPassword: '',
      errorMessage: '',
      isActive: true,
      hasError: false,
      twofaQrLoading: false,
      error: '',
      table: {
        columns: ['last_activity', 'remote_addr', 'device', 'actions'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            last_activity: this.$t('last_activity'),
            remote_addr: this.$t('remote_addr'),
            device: this.$t('device'),
            actions: '',
          },
          sortable: ['last_activity', 'remote_addr', 'device'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  computed: {
    locales() {
      let locales = []
      for (const [key, value] of Object.entries(this.$config.locale.supportedLocales)) {
        locales.push({
          key: key,
          value: value,
        })
      }
      return locales
    }
  },
  async mounted() {
    await this.loadGroups()
    this.userId = this.$auth.getUser().id
    this.$setRouteMeta(this.$t('my_account'))
    await this.load()
  },
  methods: {
    copy() {

      const storage = document.createElement('textarea');
      storage.value = this.$refs.twofaCode.innerHTML;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);

      this.$noty.success(this.$t('copied'))
    },
    async disconnect2FA() {
      if(!confirm(this.$t('twofa_confirm'))) return;

      const { data } = await this.$api.put('/user/' + this.userId+'/2fa/connect', { is_twofa: false })

      if(data.success) {
        this.data.is_twofa = false;
        this.$refs.twofaModal.hide()
        this.$noty.success(this.$t('2fa_disconnected'))
      } else {
        this.$noty.error(this.$t('wrong_pin'))
      }
    },
    async twofaConnect() {
      console.log("Try TRFA connect")
      if(!this.twofaPin || this.twofaPin.length < 6) {
        this.$noty.warning(this.$t('twofa_please_enter_pin'))
        return
      }

      const { data } = await this.$api.put('/user/' + this.userId+'/2fa/connect', { twofa_pin: this.twofaPin, is_twofa: true })

      if(data.success) {
        this.data.is_twofa = true;
        this.$refs.twofaModal.hide()
        this.$noty.success(this.$t('2fa_connected'))
      } else {
        this.$noty.error(this.$t('wrong_pin'))
      }
    },
    async open2faModal() {
      this.twofaData = null;
      this.twofaPin = null;

      this.$refs.twofaModal.show()
      this.twofaQrLoading = true
      const { data } = await this.$api.get('/user/' + this.userId+'/2fa')
      this.twofaData = data.twofaData
      this.twofaQrLoading = false

    },
    validateForm() {
      if(this.data.password === '' && this.confirmPassword === '') {
        this.hasError = false;
        this.errorMessage = '';
        return true;
      } else if (this.data.password !== this.confirmPassword) {
        this.hasError = true;
        this.errorMessage =  this.$t('confirm_password_error') ;
        return false
      } else {
        this.hasError = false;
        this.errorMessage = '';
        return true;
      }    
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    closeSession(sessionId) {
      this.loading = true
      this.$api.delete('/user-session-close/' + sessionId).then(() => {
        this.load()
        this.$noty.success(this.$t('session_closed', {id: sessionId}))
      })
    },
    setUser(resp) {
      if (resp.active_sessions === null) {
        resp.active_sessions = []
      }
      this.data = { ...resp, password: null }
    },
    async updateUser() {
      this.savingInProcess = true
      await this.$api.put('/user/' + this.userId, {
        group: this.data.group,
        name: this.data.name,
        login: this.data.login,
        password: this.data.password,
        status: this.data.status.key,
        language: this.data.language,
        is_twofa: this.data.is_twofa,
      }).then(data => {
        this.setUser(data.data)
        this.$auth.updateUser(data.data)
        this.$i18n.locale = data.data.language
        this.$setRouteMeta(this.$t('my_account'))
        this.savingInProcess = false
        this.$noty.success(this.$t('account_settings_saved'))
      }).catch(() => {
      })
      this.savingInProcess = false
    },
    async load() {
      this.loading = true
      this.error = ''
      await this.$api.get('/user/' + this.userId).then(data => {
        this.setUser(data.data)
      }).catch(() => {
        this.error = 'Error load user info'
      })
      this.loading = false
    },
    async loadGroups() {
      this.loading = true
      await this.$api.get('/user-role').then(data => {
        this.groups = []
        data.data.forEach(e => {
          if (e.display) {
            this.groups.push(e)
          }
        })
      }).catch(() => {
        this.error = 'Error load user roles'
      })
      this.loading = false
    },
  }
}
</script>
