<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini">
        <ul class="nav nav-tabs">
          <!-- Первая вкладка (активная) -->
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#parameters">{{$t('onu_registration.parameters')}}</a>
          </li>
          <!-- Вторая вкладка -->
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#templates">{{$t('onu_registration.templates')}}</a>
          </li>
        </ul>
        <div class="tab-content" style="background-color: #FFF; border-left: 1px solid #EAEAEA;border-bottom: 1px solid #EAEAEA;  border-right: 1px solid #EAEAEA;  padding: 10px">
          <!-- Первый блок (он отображается по умолчанию, т.к. имеет классы show и active) -->
          <div class="tab-pane fade show active" id="parameters">
            <ParameterConfig api-component-name="/component/huawei_onts_registration" vendor-name="Huawei"/>
          </div>
          <!-- Второй блок -->
          <div class="tab-pane fade" id="templates">
            <TemplateConfiguration api-component-name="/component/huawei_onts_registration" vendor-name="Huawei" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import ParameterConfig from "@/components/Configurations/ParameterConfig";
import TemplateConfiguration from "@/components/Configurations/TemplateConfiguration";

export default {
  components: {TemplateConfiguration, ParameterConfig},
  mounted() {
      this.$setRouteMeta(this.$t('config_huawei_onts_registration'))
    }
}
</script>


<style >
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 550px;
}
.height-300 {
  height: 300px;
}
</style>
