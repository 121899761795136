<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true">
          <div class="row">
            <div class="col-sm-6 col-lg-3 col-xl-3 col-md-6" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('user') }}</label>
                <v-select class="style-chooser" multiple
                          v-model="filter.users" label="name" :options="usersList"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-8 col-xl-4 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('contain_text') }}</label>
                <input type="text" v-model="filter.query" class="form-control">
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('hide_success') }}</label>
                <input type="checkbox" class="form-control" style="width: 30px; height: 30px; margin-top: 1px"
                       v-model="filter.only_failed">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">{{ $t('actions') }}</label>
            </div>
            <div class="col-sm-6">
              <button style="margin: 3px" class="btn btn-sm btn-success"
                      @click="actionsList.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
              <button style="margin: 3px" class="btn btn-sm btn-secondary"
                      @click="actionsList.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div style="display: inline">
                <a v-for="action in actionsList.filter(e => {return e.name.indexOf(actionQuery) !== -1})"
                   :key="action.name"
                   :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="action.selected = !action.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-plus" v-if="!action.selected"></i>
                  <i class="fa fa-minus" v-if="action.selected"></i>
                  {{ action.name }}
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-10 col-xl-10"></div>
            <div class="col-sm-6 col-lg-2 col-xl-2">
              <button @click="loadData(filter)" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-info"><i class="mdi mdi-refresh"></i> {{$t('reload_info')}}</button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-mini col-12 col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header waves-effect waves-dark" style="min-height: 50px">
            {{ $t('data') }}
          </div>
          <div class="card-body">
            <Preloader v-if="loading"/>
            <v-client-table v-model="data" :columns="table.columns" :options="table.options"
                            :style="'margin-top: 5px; position: relative;  z-index: 1; ' + ((data.length > 10) ? 'top: -67px;' : '')"
                            v-if="!loading">
              <template v-slot:child_row="{row}">
                <div class="row" v-for="(data, key) in row" :key="key" style="margin: 0; padding: 0">
                  <div class="col-sm-2" style="margin: 0; padding: 0">{{key}}</div>
                  <div class="col-sm-10"  style="margin: 0; padding: 0; max-width: 1000px;">
                    <json-tree :data="data" :level="1" ></json-tree>
                  </div>
                </div>
              </template>
              <template v-slot:user="{row}">
                <router-link :to="{name: 'management_user_edit', params: {id: row.user.id}}">{{ row.user.name }}
                </router-link>
              </template>
              <template v-slot:status="{row}">
                <span
                    :style="row.status === 'FAILED' ? 'color: darkred; font-weight: bold' : ''">{{ $t("log_statuses." + row.status) }}</span>
              </template>
              <template v-slot:action="{row}">
                <b>{{ row.action }}</b>
              </template>
            </v-client-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      data: [],
      filter: {
        range: [],
        actions: [],
        users: [],
        only_failed: false,
        query: '',
      },
      loading: false,
      actionQuery: '',
      actionsList: [],
      usersList: [],
      table: {
        columns: ['id', 'created_at', 'action', 'user', 'message', 'status'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('time'),
            action: this.$t('action'),
            user: this.$t('user'),
            message: this.$t('message'),
            status: this.$t('status'),
          },
          sortable: ['id', 'created_at', 'action', 'user', 'message', 'status'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  watch: {
    filter: {
      handler(n) {
        this.loadData(n)
      },
      deep: true,
    },
    actionsList: {
      handler(n) {
        var tagged = []
        n.forEach(e => {
          if (e.selected) {
            tagged.push(e.name)
          }
        })
        this.filter.actions = tagged
      },
      deep: true,
    }
  },
  created() {
    let currDateString = moment().format("YYYY-MM-DD");
    this.filter.range = [
      moment(currDateString).toDate(),
      moment(currDateString + "T23:59:59").toDate()
    ]
  },
  async mounted() {
    this.$setRouteMeta(this.$t('log_actions_full'))
    this.actionsList = await this.loadActions()
    this.usersList = await this.loadUsersList()
  },
  methods: {
    async loadData(filters) {
      var users = []
      filters.users.forEach(e => {
        users.push(e.id)
      })
      let status = ''
      if (filters.only_failed) {
        status = 'FAILED'
      }
      let query = {
        start: moment(filters.range[0]).format("YYYY-MM-DD HH:mm:ss"),
        stop: moment(filters.range[1]).format("YYYY-MM-DD HH:mm:ss"),
        query: filters.query,
        users: users,
        actions: filters.actions,
        status: status
      }
      console.log(query)
      this.loading = true
      this.$api.post('/logs/actions', query).then(r => {
        this.data = r.data
      }).catch(() => {
      })
      await this.$api.waitResponses()
      this.loading = false
    },
    async loadActions() {
      var options = []
      await this.$api.get('/logs/actions-list').then(r => {
        r.data.forEach(e => {
          options.push({
            name: e,
            selected: false,
          })
        })
      }).catch(() => {
      })
      return options
    },
    async loadUsersList() {
      var users = []
      await this.$api.get('/user-list').then(r => {
        users = r.data
      }).catch(() => {
      })
      return users
    }
  }
}
</script>
<style>
.mx-input {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:hover {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.mx-input:active {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.a-to-badge:active {
  color: #FAFAFA !important;
}

.a-to-badge:visited {
  color: #FAFAFA !important;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}
.json-tree {
  padding-left: 10px !important;
}
.json-tree-key {
  font-size: 14px;
}
</style>
