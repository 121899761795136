<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-mini col-sm-12">
                <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
                <Card :name="$t('filters')" :show="true"  :disable-hide="true">
                    <div class="row">
                        <div class="col-xl-4 col-sm-12 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('devices') }}</label>
                                <v-select class="style-chooser"
                                          v-model="filter.devices"
                                          label="displayName"
                                          :options="deviceList.filter(e => {return e.model.type === 'OLT' && filter.devices.filter(s => e.id === s.id).length === 0})"
                                          :multiple="true"
                                          :close-on-select="false"
                                />
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-12 col-lg-3 col-md-3">
                            <div class="form-group">
                                <label class="mb-0">{{ $t('signal_type') }}</label>
                                <v-select class="style-chooser"
                                          v-model="filter.type"
                                          label="value"
                                          :reduce="ky => ky.key"
                                          :options="signalTypes"
                                          :multiple="false"
                                          :close-on-select="true"
                                />
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-sm-4 col-md-3">
                            <div class="form-group">
                                <label class="mb-0"> &nbsp;</label>
                                <button class="btn btn-default btn-block btn-sm" style="padding: 2.5px" @click="search">
                                    <i
                                            class="mdi mdi-table-search mdi-18px"></i></button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div class="col-12 col-mini">
                <Card :name="$t('chart_data')" :show="true"  :disable-hide="true">
                    <bar-chart-container @onClick="setLevel" :height="300" ref="chart" :hide-options="true" :options="chartOptions" :call-back="chartCallBack"></bar-chart-container>
                    <small>{{$t('analytics_click_on_chart_for_load_data')}}</small>
                </Card>
                <Card :name="$t('data')" :show="true"  :disable-hide="true">
                  <div  style="float: right; font-weight: bold; padding-top: 5px; font-size: 120%; width: 50px"><span v-if="filter.level"><i class="mdi mdi-signal"></i> {{filter.level}}</span></div>
                    <v-server-table :columns="table.columns" :options="table.options" ref="table"
                                    @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                                    class="btn-add-margin-top-for-mobile table-sm">
                      <template v-slot:device="{row}">
                          <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">
                              {{ row.device.ip }} <small>({{ row.device.name }})</small>
                          </router-link>
                      </template>
                      <template v-slot:interface="{row}">
                          <router-link
                              :to="{name: 'device_iface_dashboard', params: {id: row.device.id, 'interface': row.bind_key}}">
                              {{ row.name }}
                          </router-link>
                      </template>
                      <template v-slot:status="{row}">
                          <div :style="`color: ` + (row.status === 'Online' ? 'darkgreen' : 'darkred')">
                              <div style="float: left; " :class="`status-flicker ` + (row.status === 'Online' ? 'status-online' : 'status-offline')"></div>
                              <div style="float: left; font-weight: bold">{{row.status}}</div>
                          </div>
                      </template>
                      <template v-slot:optical_rx="{row}">
                          <b>{{row.optical_rx}}</b>
                      </template>
                      <template v-slot:optical_tx="{row}">
                          <b>{{row.optical_tx}}</b>
                      </template>
                      <template v-slot:optical_olt_rx="{row}">
                          <b>{{row.optical_olt_rx}}</b>
                      </template>

                    </v-server-table>
                </Card>
            </div>
        </div>
    </div>
</template>


<script>
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import DefaultChartOptions from "@/components/Charts/options/line/default";

import ChartCallBack from "@/components/Charts/callback";
import BarChartContainer from "@/components/Charts/BarChartContainer.vue";

var filter = {}

export default {
    components: {
      BarChartContainer,
        FullscreenPreloader,
        Card,
    },
    data() {
        return {
            data: [],
            filter: {
                devices: [],
                type: 'rx',
                level: null,
            },
            chartOptions: DefaultChartOptions,
            chartCallBack: (new ChartCallBack()),
            preloading: true,
            chartLoading: true,
            actionQuery: '',
            deviceList: [],
            signalTypes: [
              {key: 'rx', value: 'RX'},
              {key: 'tx', value: 'TX'},
              {key: 'olt_rx', value: 'Olt RX'},
            ],
          table: {
                columns: ['device', 'interface', 'status', 'description', 'optical_rx', 'optical_tx', 'optical_olt_rx'],
                options: {
                    requestFunction(data) {
                        data.filter = JSON.parse(JSON.stringify(filter))
                        switch (data.orderBy) {
                            case 'device':
                                data.orderBy = 'interface.device.ip';
                                break;
                            case 'interface':
                                data.orderBy = 'iface_name';
                                break;
                        }
                        return this.$api.put('/component/analytics/table/signal-strength', data).catch(function (e) {
                            this.dispatch('error', e);
                        }).then(resp => {
                            resp.count = resp.meta.total_records
                            return resp;
                        });
                    },
                    skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
                    perPage: 300,
                    perPageValues: [300, 500, 1000],
                    headings: {
                        'device': this.$t('device'),
                        'interface': this.$t('interface'),
                        'status': this.$t('status'),
                        'description': this.$t('description'),
                        'optical_rx': this.$t('rx'),
                        'optical_tx': this.$t('tx'),
                        'optical_olt_rx': this.$t('olt_rx'),
                    },
                    sortable: ['device', 'interface',  'status'],
                   // filterable: false,
                    texts: {
                        count: this.$t('dt_table.count'),
                        first: this.$t('dt_table.first'),
                        last: this.$t('dt_table.last'),
                        filter: this.$t('dt_table.filter'),
                        filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
                        limit: this.$t('dt_table.limit'),
                        page: this.$t('dt_table.page'),
                        noResults: this.$t('dt_table.noResults'),
                        filterBy: this.$t('dt_table.filterBy'),
                        loading: this.$t('dt_table.loading'),
                        defaultOption: this.$t('dt_table.defaultOption'),
                        columns: this.$t('dt_table.columns'),
                    },
                },
            },
        }
    },
    created() {
        filter = this.filter
    },
    watch: {
      'filter.level': {
        handler(n) {
          this.$refs.table.refresh()
        },
        deep: true,
      },
    },
    async mounted() {
        this.$setRouteMeta(this.$t('ont_statuses'))
        this.$api.enableSupportWaiting()
        this.loadDevicesList()
        if (typeof this.$route.query.device_id !== 'undefined') {
            console.log("DeviceID setted")
            this.filter.devices.push(await this.getDevice(this.$route.query.device_id))
        }
        await this.$api.waitResponses()
        this.loadChart()
        this.preloading = false
    },
    methods: {
        setLevel(data) {
            console.log(data)
            this.filter.level = data
        },
        async search() {
            this.filter.level = null
            this.loadChart()
            this.$refs.table.refresh()
        },
        async loadUsersList() {
            await this.$api.get('/user-list').then(r => {
                this.usersList = r.data
            }).catch(() => {
            })
        },
        async loadChart() {
            this.chartCallBack.setUri('/component/analytics/bars/ont-levels')
            console.log(this.filter)
            this.chartCallBack.setParams({
                filter: this.filter,
            })
            this.chartLoading = true
            await this.$refs.chart.load()
            this.chartLoading = false
        },
        async loadDevicesList() {
            await this.$api.get('/component/analytics/parameters/device-list?type=OLT').then(r => {
                this.deviceList = []
                r.data.forEach(elem => {
                  if(!elem.enabled) return;
                    if (elem.name.trim() === '') {
                        elem.name = this.$t('no_name')
                    }
                    elem.displayName = `${elem.ip} - ${elem.name}`
                    this.deviceList.push(elem)
                })
            }).catch(() => {
            })
        },
        async getDevice(deviceId) {
            let data = {}
            await this.$api.get('/device/' + deviceId).then(r => {
                r.data.displayName = `${r.data.ip} - ${r.data.name}`
                data = r.data
            }).catch(() => {
            })
            return data
        }
    }
}
</script>
<style>
.mx-input {
    border-radius: 2px !important;
    border: 1px solid #e9ecef;
}

.mx-input:hover {
    border-radius: 2px !important;
    border: 1px solid #a0a0a0;
}

.mx-input:active {
    border-radius: 2px !important;
    border: 1px solid #a0a0a0;
}

.a-to-badge {
    color: #FAFAFA !important;
}

.a-to-badge:active {
    color: #FAFAFA !important;
}

.a-to-badge:visited {
    color: #FAFAFA !important;
}

.a-to-badge {
    color: #FAFAFA !important;
}

.VueTables__limit-field label {
    display: inline;
    margin: 5px;
}

.json-tree {
    padding-left: 10px !important;
}

.json-tree-key {
    font-size: 14px;
}
.status-flicker {
    animation: flicker 2s infinite;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 50%;

}
.status-online {
    -webkit-box-shadow: 0px 0px 5px 0px darkgreen;
    -moz-box-shadow: 0px 0px 5px 0px darkgreen;
    box-shadow: 0px 0px 5px 0px darkgreen;
    background: darkgreen;
}
.status-offline {
    -webkit-box-shadow: 0px 0px 5px 0px darkred;
    -moz-box-shadow: 0px 0px 5px 0px darkred;
    box-shadow: 0px 0px 5px 0px darkred;
    background: darkred;
}
@keyframes flicker {
    from { opacity: 1; }
    to { opacity: 0.8; }
}
</style>