<template>
  <div class="container-fluid">
    <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true"  :disable-hide="true">
          <div class="row">
            <div class="col-xl-6 col-sm-6 col-lg-6 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('device_groups') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.device_groups"
                          label="name"
                          :options="groupsList.filter(f => {return !filter.device_groups.includes(f)})"
                          :multiple="true"
                          :close-on-select="false"
                />
              </div>
            </div>
            <div class="col-xl-4 col-sm-6 col-lg-4 col-md-4" style="min-width: 150px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('duplicated_mac') }}</label>
                <input class="form-control" v-model="filter.ont_ident">
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-sm-2 col-md-2">
              <div class="form-group">
                <label class="mb-0"> &nbsp;</label>
                <button class="btn btn-default btn-block btn-sm" style="padding: 2.5px" @click="loadDevicesList">
                  <i class="mdi mdi-table-search mdi-18px"></i></button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div class="col-12 col-mini" v-if="data.length === 0">
        <h4 style="text-align: center; margin: 15px">{{$t('dt_table.noResults')}}</h4>
      </div>
      <div class="col-12 col-mini" v-else>
          <v-client-table v-model="data" :columns="table.columns" :options="table.options">
            <template v-slot:duplicates="{row}">
              <div v-for="iface in row.ifaces" :key="iface.id">
                <router-link :to="{name: 'device_dashboard', params: {id: iface.device.id}}">{{iface.device.ip}}</router-link> - <router-link :to="{name: 'device_iface_dashboard', params: {id: iface.device.id, 'interface': iface.bind_key}}">{{iface.name}}</router-link>
              </div>
            </template>
          </v-client-table>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";

var filter = {}

export default {
  components: {
    FullscreenPreloader,
    Card,
  },
  data() {
    return {
      data: [],
      filter: {
        device_groups: [],
        mac_address: '',
      },
      preloading: true,
      groupsList: [],
      table: {
        columns: ['first', 'last', 'mac_address', 'count_duplicates', 'duplicates'],
        options: {
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          perPage: 300,
          perPageValues: [300, 500, 1000],
          headings: {
            'first': this.$t('first'),
            'last': this.$t('last'),
            'mac_address': this.$t('mac_address'),
            'count_duplicates': this.$t('count_duplicates'),
            'duplicates': this.$t('duplicates'),
          },
          sortable: ['device', 'interface',  'status'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  async mounted() {
    this.preloading = true
    if(this.$route.query.mac_address) {
      this.filter.mac_address = this.$route.query.mac_address
    }
    this.$setRouteMeta(this.$t('duplicated_macs'))
    await this.getDevicesGroups()
    await this.loadDevicesList()
    this.preloading = false
  },
  methods: {
    async loadDevicesList() {
      this.preloading = true
      await this.$api.put('/component/analytics/table/duplicated-mac-addresses', this.filter).then(r => {
        this.data = []
        r.data.forEach(elem => {
          this.data.push(elem)
        })
      }).catch(() => {
      })
      this.preloading = false
    },
    async getDevicesGroups() {
      await this.$api.get('/device-group').then(r => {
        this.groupsList = r.data
      }).catch(() => {
      })
    }
  }
}
</script>
<style>
.mx-input {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:hover {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.mx-input:active {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.a-to-badge:active {
  color: #FAFAFA !important;
}

.a-to-badge:visited {
  color: #FAFAFA !important;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}
.status-flicker {
  animation: flicker 2s infinite;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border-radius: 50%;

}
.status-online {
  -webkit-box-shadow: 0px 0px 5px 0px darkgreen;
  -moz-box-shadow: 0px 0px 5px 0px darkgreen;
  box-shadow: 0px 0px 5px 0px darkgreen;
  background: darkgreen;
}
.status-offline {
  -webkit-box-shadow: 0px 0px 5px 0px darkred;
  -moz-box-shadow: 0px 0px 5px 0px darkred;
  box-shadow: 0px 0px 5px 0px darkred;
  background: darkred;
}
@keyframes flicker {
  from { opacity: 1; }
  to { opacity: 0.8; }
}
</style>