<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini">
        <!-- BLOCK OF CHOOSE DEVICE AND ONT FOR FILL PARAMETERS -->
        <div class="row">
          <div class="col-sm-12" v-html="$t('onu_registration.variable_block')" style="margin-bottom: 15px;"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('device') }}</label>
              <v-select class="style-chooser" v-model="device" label="displayName" :options="devicesList"></v-select>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
            <div class="form-group">
              <label class="mb-0">{{ $t('onu_registration.unregistered_ont') }}</label>
              <v-select class="style-chooser" v-model="ont" label="name" :options="unregisteredOnts"
                        :disabled="unregisteredOnts.length === 0"></v-select>
            </div>
          </div>
        </div>
        <!-- END BLOCK OF CHOOSE DEVICE AND ONT FOR FILL PARAMETERS -->
        <form class="row">
          <div class="col-sm-12 " v-if="parameterConfigurations.length !== 0"><h4>
            {{ $t('onu_registration.registration_form') }}</h4></div>
          <div class="col-sm-6 col-lg-6 col-xl-6" v-for="(param, id) in parameterConfigurations" :key="id" v-show="param.visible">
            <div class="form-group" v-if="param.type === 'select_from_variable'">
              <label class="mb-0">{{ param.name }}</label>
              <v-select class="style-chooser" v-model="parameters[param.key]" :options="param.variants" height="20"
              ></v-select>
              <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
            </div>
            <div class="form-group" v-if="param.type === 'select_from_predefined'">
              <label>{{ param.name }}</label>
              <v-select class="style-chooser" v-model="parameters[param.key]" :options="param.variants" height="20"
              ></v-select>
              <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
            </div>
            <div class="form-group" v-if="param.type === 'input_variable'">
              <label>{{ param.name }}</label>
              <input v-model="parameters[param.key]" class="form-control">
              <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
            </div>
            <div class="form-group" v-if="param.type === 'input_string'">
              <label>{{ param.name }}</label>
              <input v-model="parameters[param.key]" class="form-control">
              <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
            </div>
          </div>
        </form>
        <Preloader v-if="variables === null && loadingVariables"></Preloader>
        <div class="row" v-if="variables !== null">
          <div class="col-sm-12">
            <h4>{{ $t('onu_registration.variables') }}</h4>
            <div style="overflow-y: auto; max-height: 400px; background: #FAFAFA; border: 1px solid #DADADA">
              <json-tree :data="variables" :level="1"></json-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12  col-mini">
        <hr>
      </div>
      <div class="col-12  col-mini d-flex align-items-center justify-content-between">
        <h4>{{ $t('onu_registration.template_block') }}</h4>
        <button @click="saveTemplates(templates)" class="btn btn-success btn-block"
                style="width: 200px;"><i class="fa fa-save"></i>
          {{ $t('onu_registration.save_template') }}
        </button>
      </div>
      <div class="col-sm-12 mb-2 d-flex  col-mini" style="gap: 5px;flex-wrap: wrap; margin-top: 10px">
        <div v-for="tempName in templateNames"
             :key="tempName"
             @click.prevent="choseTemplate(tempName)"
             class="btn btn-secondary d-flex align-items-center"
             style="gap:5px;"
             :class="{'btn-info': tempName === selectedTemplate}"
        >
          {{ tempName }}
          <i v-if="tempName!=='main'" class="mdi mdi-close" style="font-size: 18px;font-weight: bold;" @click.prevent.stop="removeTemplate(tempName)"></i>
        </div>
        <button type="button" class="btn btn-primary" @click="addTemplate">Add +</button>
      </div>
      <div class="col-12  col-mini"  >
        <div class="row"  >
          <div class="col-sm-12 col-md-12 col-lg-6  col-xl-6" style="">
              <div  style="font-weight: bold">{{ $t('onu_registration.choosed_template') }}: {{selectedTemplate}}</div>
              <pre  v-for="tempName in templateNames" :key="tempName" v-show="tempName === selectedTemplate"><code contenteditable @input="changeTemp" class="language-twig">{{ templates[tempName] }}</code></pre>
          </div>
          <div class="col-sm-12  col-md-12 col-lg-6 col-xl-6" style="">
              <div style="font-weight: bold">{{ $t('onu_registration.live_result') }}</div>
              <Preloader v-if="live_result.loading" />
              <div class="" v-else-if="live_result.error !== null" style="text-align: center">
                <br>
                <h3>{{ $t('onu_registration.template_compile_problem') }}<br>
                  <small>{{ live_result.error.response.data.error.description }}</small>
                </h3>
              </div>
              <pre v-if="!live_result.loading && live_result.error === null"><code class="language-twig">{{ live_result.result }}</code></pre>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px" class="col-12 col-mini col-sm-12 col-lg-12 col-xl-12" v-html="$t('onu_registration.twig_easy_doc')"></div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  components: {Preloader},
  props: {
    apiComponentName: {
      type: String,
      required: true
    },
    vendorName: {
      type: String,
      required: true,
    }
  },
  async mounted() {
    this.templates = await this.getTemplate()
    this.devicesList = await this.loadDevicesForSelect()

    this.$nextTick(() => {
        document.querySelectorAll('pre code.language-twig').forEach((el) => {
            window.hljs.highlightElement(el);
        });
    })
  },
  data() {
    return {
      loading: true,
      loadingVariables: false,
      parameters: {},
      parameterConfigurations: [],
      device: null,
      devicesList: [],
      unregisteredOnts: [],
      ont: null,
      variables: null,
      templates: {},
      live_result: {
        loading: false,
        result: '',
        error: null,
      },
      template: null,
      selectedTemplate: 'main',
      newTempName: null,
    }
  },
  watch: {
    templates: {
      handler(n) {
        this.renderTemplate(n)
      },
      deep: true,
    },
    parameters: {
      handler(n) {
        this.parameterConfigurations.forEach((elem, key) => {
          if (elem.visible_if !== '') {
            this.parameterConfigurations[key].visible = this.evalCondition(elem.visible_if, this.variables)
          } else {
            elem.visible = true
          }
        })
        if (typeof this.variables.params !== 'undefined') {
          this.variables.params = n
          this.renderTemplate(this.templates)
        }
      },
      deep: true,
    },
    ont: {
      handler() {
        this.reloadForm()
      },
      deep: true,
    },
    device: {
      async handler() {
        await this.reloadForm()
      },
      deep: true,
    },
  },
  computed: {
    templateNames() {
      return Object.keys(this.templates)
    }
  },
  methods: {
    async addTemplate() {
      this.newTempName = prompt('Enter the name')

      if(this.newTempName) {
        this.newTempName = this.newTempName.replace(/-([a-z])/g, function(k){
          return k[1].toUpperCase();
        }).replace(/ ([a-z])/g, function(k){
          return k[1].toUpperCase();
        }).replace(/[\W_]+/g,"");

        this.$set(this.templates, this.newTempName, null)

        await this.$nextTick(() => {
          document.querySelectorAll('pre code.language-twig').forEach((el) => {
            if(!el.classList.contains('hljs')) {
              window.hljs.highlightElement(el);
            }
          });
        })
      }
    },
    removeTemplate(name) {
      if(confirm('Do you really want to remove this template?')) {
        this.selectedTemplate = 'main'
        this.template = this.templates['main']

        this.$delete(this.templates, name);
      }
    },
    choseTemplate(name) {
      this.selectedTemplate = name
      this.template = this.templates[name]
    },
    changeTemp(el) {
        this.templates[this.selectedTemplate] = el.target.innerText
    },
    evalCondition(condition, context) {
      //# Return the results of the in-line anonymous function we .call with the passed context
      let js = `${condition}`
      return function () {
        for (const [key, value] of Object.entries(context)) {
          window[key] = value
        }
        return eval(js);
      }.call(context);
    },
    renderTemplate(templates) {
      if (this.variables === null) {
        return
      }
      this.live_result.loading = true
      this.$api.post(this.apiComponentName + '/render_template', {
        templates: templates,
        parameters: this.variables,
      }, true).then(r => {
        this.live_result.error = null
        this.live_result.result = r.data
      }).catch(e => {
        this.live_result.error = e
      }).finally(() => {
        this.live_result.loading = false
         this.$nextTick(() => {
           document.querySelectorAll('pre code.language-twig').forEach((el) => {
             if (!el.classList.contains('hljs')) {
               window.hljs.highlightElement(el);
             }
           });
         })
      })
    },
    async loadParameters() {
      var parameters = {}
      await this.$api.get(this.apiComponentName + '/parameters/' + this.device.id + '/' + this.ont.key).then(r => {
        parameters = r.data
      })
      return parameters
    },
    async reloadForm() {
      this.loading = true
      if (this.device !== null && this.ont !== null) {
        this.variables = await this.loadVariables()
        let paramConfig = await this.loadParameters()
        paramConfig.forEach(e => {
          switch (e.type) {
            case 'select_from_variable':
            case 'select_from_predefined':
              if (e.variants.length > 0) {
                this.$set(this.parameters, e.key, e.variants[0])
              }
              break;
            default:
              this.$set(this.parameters, e.key, e.value)
          }
          this.$set(this.parameters, 'error', '')
          this.$set(this.parameters, 'visible', true)
        })
        this.parameterConfigurations = paramConfig
      } else if (this.ont === null && this.device !== null) {
        this.unregisteredOnts = await this.loadOntsForSelect(this.device.id)
      } else {
        this.parameterConfigurations = []
        this.variables = {}
        this.unregisteredOnts = []
        this.parameters = {}
      }
      this.loading = false
    },
    async getTemplate() {
      const data = await this.$api.get(this.apiComponentName + '/template')

      return data?.data
    },
    async saveTemplates(templates) {
      await this.$api.put(this.apiComponentName + '/template', {templates: templates}).then(() => {
        this.$noty.success(this.$t('onu_registration.template_success_saved'))
      })
    },
    async loadDevicesForSelect() {
      var data = []
      data.push({
        name: "Mock device",
        id: -1,
        displayName: "Mock device",
      })
      await this.$api.get('/device?limit=99999&query=OLT&ascending=name').then(r => {
        r.data.forEach(d => {
          if(!d.enabled) return;
          if (d.name.trim() === '') {
            d.name = this.$t('no_name')
          }
          if(d.model.vendor !== this.vendorName || d.model.type !== 'OLT') {
            return
          }
          d.displayName = `${d.ip} - ${d.name}`
          data.push({
            displayName: d.displayName,
            name: d.name,
            id: d.id
          })
        })
      })
      return data
    },
    async loadOntsForSelect(deviceId) {
      var variants = []
      variants.push({
        name: "Mock GPON ONU",
        key: 'mock_gpon_onu',
      })
      variants.push({
        name: "Mock EPON ONU",
        key: 'mock_epon_onu',
      })
      await this.$api.get(this.apiComponentName + '/unregistered/' + deviceId).then(r => {
        r.data.forEach(elem => {
          let ident = ''
          if(elem.serial) ident = elem.serial
          if(elem.mac_address) ident = elem.mac_address

          variants.push({
            key: ident,
            name: elem.interface.name + ' - ' + ident,
          })
        })
      }).catch(e => {
        console.log(e)
      })
      return variants
    },
    async loadVariables() {
      var variables = []
      this.loadingVariables = true
      if (this.ont === null) {
        await this.$api.post(this.apiComponentName + '/variables/' + this.device.id).then(r => {
          variables = r.data
        })
      } else {
        await this.$api.post(this.apiComponentName + '/variables/' + this.device.id + '/' + this.ont.key).then(r => {
          variables = r.data
        })
      }
      this.loadingVariables = false
      return variables
    }
  },
}
</script>


<style>
.my-editor {
  border: 1px solid #DADADA;
  background: #FAFAFA;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  width: 100%;
  height: 450px;
}

.height-300 {
  height: 300px;
}


/* for block of numbers */
.hljs-ln-numbers {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    text-align: center;
    color: #ccc;
    border-right: 1px solid #CCC;
    vertical-align: top;
    padding-right: 5px !important;

    /* your custom style here */
}

/* for block of code */
.hljs-ln-code {
    padding-left: 10px !important;
}
</style>
