<template>
    <form class="row">
      <div class="col-sm-12 " v-if="form?.length === 0"><h4
          style="text-align: center; margin: 15px; padding-bottom: 10px">
        {{ $t('macros.params_empty') }}</h4></div>
      <div class="col-sm-6 col-lg-6 col-xl-6" v-for="(param, id) in form" :key="id" v-show="param.visible">
        <div class="form-group" v-if="param.type === 'select_from_variable'">
          <label class="mb-0">{{ param.name }}</label>
          <v-select class="style-chooser" :label="param.isSimpleArray ? '' : '_display_name'"
                    v-model="parameters[param.key]" :options="param.variants" height="20"
          ></v-select>
          <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
        </div>
        <div class="form-group" v-if="param.type === 'select_from_predefined'">
          <label class="mb-0">{{ param.name }}</label>
          <v-select class="style-chooser" v-model="parameters[param.key]" :options="param.variants" height="20"
          ></v-select>
          <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
        </div>
        <div class="form-group" v-if="param.type === 'input_variable'">
          <label class="mb-0">{{ param.name }}</label>
          <input v-model="parameters[param.key]" class="form-control">
          <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
        </div>
        <div class="form-group" v-if="param.type === 'input_string'">
          <label class="mb-0">{{ param.name }}</label>
          <input v-model="parameters[param.key]" class="form-control">
          <span style="color: darkred" v-if="param.error !== ''">{{ param.error }}</span>
        </div>
      </div>
    </form>
</template>
<script>

export default {
  props: {
    input: Object,
    formDeclaration: Array,
    vars: Object,
  },
  watch: {
    parameters: {
      handler(newVal) {
        this.recalcVisible(newVal)
        this.$emit('input', newVal);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        this.parameters = newVal
      },
      deep: true,
    },
    vars: {
      handler(n) {
        this.recalcVisible()
        this.variables = n
      },
      deep: true,
    },
    formDeclaration: {
      handler( ) {
        this.recalcVisible()
      },
      deep: true,
    },
  },
  mounted() {
    if(this.input) {
      this.parameters = this.input
    }
    if(this.vars) {
      this.variables = this.vars
    }
    this.setDefaultParameterValuesFromVariables()
    this.buildFormFromDeclaration()
    this.recalcVisible()
  },
  data() {
    return {
      parameters: {},
      form: [],
      variables: {}
    }
  },
  methods: {
    rebuildForm() {
      this.setDefaultParameterValuesFromVariables()
      this.buildFormFromDeclaration()
      this.recalcVisible()
    },
    setDefaultParameterValuesFromVariables() {
      console.log(this.formDeclaration)
      const get = (t, path) =>
          path.split(".").reduce((r, k) => r?.[k], t)
      this.formDeclaration.forEach(e => {
        switch (e.type) {
          case 'select_from_variable':
            if (get(this.variables, e.source_parameter_key)) {
              let variants = get(this.variables, e.source_parameter_key)
              if(e.item_filter && e.item_filter !== '') {
                variants = variants.filter(f => {
                  let evalRes = this.evalCondition(e.item_filter, {item: f})
                  if(evalRes.error) {
                    console.log("error eval condition '"+e.item_filter+"' for param with item:", f)
                    console.log(evalRes.error)
                    alert("Error evaluate condition for variables list! See browser logs (F12)")
                  }
                  return evalRes.condition
                })
              }
              if(variants.length > 0) {
                this.$set(this.parameters, e.key, variants[0])
              }
            }
            break;
          case 'select_from_predefined':
            if (e.variants_list?.length > 0) {
              this.$set(this.parameters, e.key, e.variants_list.split('\n')[0])
            }
            break;
          case 'input_string':
            this.$set(this.parameters, e.key, e.default_value)
                break;
          default:
            this.$set(this.parameters, e.key, e.value)
        }
      })
    },
    buildFormFromDeclaration() {
      this.form = []
      const get = (t, path) =>
          path.split(".").reduce((r, k) => r?.[k], t)
      console.log(this.formDeclaration)
      this.formDeclaration.forEach(e => {
        if (e.visible_if !== '') {
          e.visible = false
        } else {
          e.visible = true
        }
        e.error = ''

        if (e.type === 'select_from_predefined') {
          e.variants = e.variants_list.split('\n')
        } else if (e.type === 'select_from_variable') {
          let variants = get(this.variables, e.source_parameter_key)
          let isSimpleArray = true;
          for (const i in variants) {
            let type = typeof variants[i]
            if(type === "function") {
              continue
            }
            if (type !== "number" && type !== "string") {
              isSimpleArray = false
              break;
            }
          }
          e.isSimpleArray = isSimpleArray
          if(e.item_filter && e.item_filter !== '') {
            variants = variants.filter(f => {
              let evalRes = this.evalCondition(e.item_filter, {item: f})
              if(evalRes.error) {
                console.log("error eval condition '"+e.item_filter+"' for param with item:", f)
                console.log(evalRes.error)
                alert("Error evaluate condition for variables list! See browser logs (F12)")
              }
              return evalRes.condition
            })
          }
          if (e.item_name && !isSimpleArray) {
            try {
              variants.forEach((variant, index) => {
                let exec = this.evalCondition("`" + e.item_name + "`", {item: variant})
                variants[index]['_display_name'] = exec.condition
              })
            } catch (e) {
              console.error(e)
            }
          } else if(!isSimpleArray) {
            variants.forEach((variant, index) => {
              if(variant.name) {
                variants[index]['_display_name'] = variant.name
              } else {
                variants[index]['_display_name'] = variant
              }
            })
          }
          e.variants = variants
        }
        this.form.push(e)
      })
    },
    recalcVisible() {
      if (this.variables) {
        this.variables.params = this.parameters
      }
      this.form.forEach((elem, key) => {
        if (elem.visible_if !== '') {
          let evalRes = this.evalCondition(elem.visible_if, this.variables)
          if(evalRes.error) {
            console.log("error eval condition '"+elem.visible_if+"' for param with key "+ key)
            console.log(evalRes.error)
            alert("Error evaluate condition! See browser logs (F12)")
          }
          this.form[key].visible = evalRes.condition
        } else {
          elem.visible = true
        }
      })

    },
    isFormValid() {
      let isValid = true
      this.form.forEach((e, k) => {
        let hasError = false
        if (!e.visible) {
          this.form[k].error = ''
        } else if (e.required && this.parameters[e.key] === null) {
          this.form[k].error = this.$t('field_is_required')
          hasError = true
        } else if (e.required && typeof this.parameters[e.key] === 'string' && this.parameters[e.key].trim() === '') {
          this.form[k].error = this.$t('field_is_required')
          hasError = true
        } else if (e.regular_expr && !(new RegExp(e.regular_expr)).test(this.parameters[e.key])) {
          this.form[k].error = this.$t('incorrect_field_must_be_regex', {'regexp': e.regular_expr})
          hasError = true
        }
        if (hasError) {
          isValid = false
          console.log("Form not valid by parameter", e.key)
        } else {
          this.form[k].error = ''
        }
      })
      return isValid
    },
    evalCondition(condition, context) {
      //# Return the results of the in-line anonymous function we .call with the passed context
      let js = `${condition}`
      return function () {
        for (const [key, value] of Object.entries(context)) {
          window[key] = value
        }
        let condition = false
        let error = ''
        try {
          condition = eval(js);
        } catch (e) {
          error = e
          console.log(e.message);              // "Привет"
          console.log(e.name);                 // "EvalError"
          console.log(e.fileName);             // "someFile.js"
          console.log(e.lineNumber);           // 10
          console.log(e.columnNumber);         // 0
          console.log(e.stack);                // "@Scratchpad/2:2:9\n"
        }
        return {
          condition: condition,
          error: error,
        }
      }.call(context);
    },
  }
}
</script>