<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <Card :name="$t('filters')" :show="true">
          <div class="row">
            <div class="col-sm-12 col-lg-6 col-xl-3 col-md-12" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('severity') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.severity" label="name" :options="severities"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-3 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('device') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.device" label="displayName" :options="deviceList"></v-select>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-2 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('only_not_resolved') }}</label>
                <br>
                <label class="switch mb-0">
                  <input type="checkbox" v-model="filter.only_not_resolved">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="col-sm-6 col-lg-8 col-xl-4 col-md-6">
              <div class="form-group">
                <label class="mb-0">{{ $t('contain_text') }}</label>
                <input type="text" v-model="filter.query" class="form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">{{ $t('names') }}</label>
            </div>
            <div class="col-sm-6">
              <button style="margin: 3px" class="btn btn-sm btn-success"
                      @click="eventNames.forEach(e => {e.selected = true})"><i class="fa fa-plus"></i>
                {{ $t('select_all') }}
              </button>
              <button style="margin: 3px" class="btn btn-sm btn-secondary"
                      @click="eventNames.forEach(e => {e.selected = false})"><i class="fa fa-minus"></i>
                {{ $t('unselect_all') }}
              </button>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 col-md-12">
              <div style="display: inline">
                <a v-for="action in eventNames.filter(e => {return e.name.indexOf(actionQuery) !== -1})"
                   :key="action.name"
                   :class="'badge badge-pill a-to-badge' + (action.selected ? ' badge-success':' badge-secondary')"
                   style="font-size: 15px; margin: 3px"
                   @click="action.selected = !action.selected"
                   href="javascript:void(0)"
                >
                  <i class="fa fa-plus" v-if="!action.selected"></i>
                  <i class="fa fa-minus" v-if="action.selected"></i>
                  {{ action.name }}
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-lg-7 col-xl-7"></div>
            <div class="col-sm-6 col-lg-3 col-xl-3">
              <button v-if="$auth.isPermitted('events_resolve')" @click="resolveAll" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-danger"><i
                  class="mdi mdi-redo"></i> {{ $t('resolve_displayed') }}
              </button>
            </div>
            <div class="col-sm-6 col-lg-2 col-xl-2">
              <button @click="loadData()" style="margin-top: 10px" class="btn btn-sm btn-block btn-outline-info"><i
                  class="mdi mdi-refresh"></i> {{ $t('reload_info') }}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-12 col-lg-12 col-md-12">
        <Preloader v-if="loading"/>
        <v-server-table :columns="table.columns" :options="table.options" ref="table"  v-if="displayTable"
                        v-show="!loading"
                        @loading="loading = true"
                        @loaded="loading = false"
        >
          <template v-slot:child_row="{row}">
            <div class="row" style="margin-left: 0; margin-right: 0; margin-top: 10px; padding: 0">
              <div class="col-sm-2" >{{ $t('description') }}</div>
              <div class="col-sm-10">
                <b>{{ row.description }}</b>
              </div>
            </div>
            <hr>
            <div class="row"  style="margin: 0;">
              <div class="col-sm-2" >{{ $t('created_at') }}</div>
              <div class="col-sm-10"  >
                {{ row.created_at }} (by {{ row.creator.name }})
              </div>
            </div>
            <div class="row" style="margin: 0;">
              <div class="col-sm-2" >{{ $t('resolved_at') }}</div>
              <div class="col-sm-10">
                <div v-if="row.resolved_by !== null">
                  {{ row.resolved_at }} (by {{ row.resolved_by.name }})
                </div>
                <div  v-else>{{$t('not_resolved') }}</div>
                <button v-if="$auth.isPermitted('events_resolve') && row.resolved_at == null" style="float: right" class="btn btn-sm btn-info" @click="resolveEvent(row)">{{$t('resolve')}}</button>

              </div>

            </div>
            <hr>
            <div class="row" v-if="row.device !== null" style="margin: 0;">
              <div class="col-sm-2" >{{ $t('device') }}</div>
              <div class="col-sm-10">
                <div>
                  <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}">{{ row.device.name }}
                    ({{ row.device.ip }})
                  </router-link>
                </div>
                <div v-if="row.labels.iface_id">
                  <router-link :to="{name: 'device_iface_dashboard', params: {id: row.device.id, interface: row.labels.iface_id}}">
                    {{ row.labels.iface_name }}
                    <small>({{ row.labels.iface_id }})</small>
                  </router-link>
                </div>
              </div>
            </div>
            <hr>
            <div class="row" style="margin: 0;">
              <div class="col-sm-2" >{{ $t('labels') }}</div>
              <div class="col-sm-10">
                <li v-for="(k, v) in row.labels" :key="v">{{ v }}: <b>{{ k }}</b></li>
              </div>
            </div>
            <hr>
          </template>
          <template v-slot:created_at="{row}">
            <div style="min-width: 70px;">{{ row.created_at }}</div>
          </template>
          <template v-slot:resolved_at="{row}">
            <div style="min-width: 70px;">{{ row.resolved_at }}</div>
          </template>
          <template v-slot:description="{row}">
            <div style="min-width: 250px;">{{ row.description }}</div>
          </template>
          <template v-slot:name="{row}">
            <div style="font-weight: bold">{{ row.name }}</div>
          </template>
          <template v-slot:severity="{row}">
            <div style="font-weight: bold" :class="`row-severity-${row.severity}`">{{ row.severity }}<br>
              <small>
                {{row.id}}
              </small></div>
          </template>
          <template v-slot:device="{row}">
            <div v-if="row.device !== null">
              <router-link :to="{name: 'device_dashboard', params: {id: row.device.id}}"> {{ row.device.ip }}</router-link>
              <div v-if="row.labels.iface_id">
                <router-link :to="{name: 'device_iface_dashboard', params: {id: row.device.id, interface: row.labels.iface_id}}">
                  <small>{{ row.labels.iface_name }} </small>
                </router-link>
              </div>
            </div>
            <div v-else>{{ $t('no_device') }}</div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"

export default {
  components: {
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      displayTable: false,
      severities: [
        'INFO',
        'WARNING',
        'CRITICAL',
      ],
      filter: {
        range: [],
        only_not_resolved: false,
        query: '',
        severity: null,
        device: {
          id: null,
          name: "",
        },
        names: [],
      },
      loading: false,
      actionQuery: '',
      eventNames: [],
      usersList: [],
      deviceList: [],
      table: {
        columns: ['severity', 'created_at', 'resolved_at', 'name', 'device', 'description'],
        options: {
          requestFunction: (data) => {
            console.log(data)
            data.names = this.filter.names
            data.start = moment(this.filter.range[0]).format("YYYY-MM-DD HH:mm:ss")
            data.end = moment(this.filter.range[1]).format("YYYY-MM-DD HH:mm:ss")
            data.device = this.filter.device
            data.query = this.filter.query
            data.not_resolved = this.filter.only_not_resolved
            data.severity = this.filter.severity
            return this.$api.post('/component/events', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          rowClassCallback(row) {
            if(row.resolved_at !== null) {
              return  'row-resolved'
            }
            return ''
          },
          perPage: 100,
          skin: 'VueTables__table table table-sm table-bordered table-hover styled-table',
          headings: {
            id: 'Id',
            created_at: this.$t('time'),
            name: this.$t('name'),
            description: this.$t('description'),
            severity: this.$t('severity'),
            resolved_at: this.$t('resolved_at'),
          },
          sortable: ['id', 'created_at', 'severity', 'device'],
          filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  watch: {
    filter: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    eventNames: {
      handler(n) {
        var tagged = []
        n.forEach(e => {
          if (e.selected) {
            tagged.push(e.name)
          }
        })
        this.filter.names = tagged
      },
      deep: true,
    }
  },
  created() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 30));
    this.filter.range = [
      priorDate,
      today,
    ]
  },
  async mounted() {
    if (typeof this.$route.query.device_id !== 'undefined') {
      console.log("DeviceID setted")
      this.filter.device = await this.getDevice(this.$route.query.device_id)
    }
    if (typeof  this.$route.query.only_not_resolved !== 'undefined') {
      this.filter.only_not_resolved = true
    }
    if (typeof  this.$route.query.severity !== 'undefined') {
      this.filter.severity = this.$route.query.severity
    }
    this.$setRouteMeta(this.$t('events.name'))
    await this.loadNames()
    await this.loadDevicesList()
    if (typeof  this.$route.query.event_name !== 'undefined') {
      this.eventNames.forEach(n => {
        if(this.$route.query.event_name === n.name) {
          n.selected = true
        }
      })
    }
    this.displayTable = true
  },
  methods: {
    async loadNames() {
      await this.$api.get('/component/events/params/names').then(r => {
        this.eventNames = []
        r.data.forEach(e => {
          this.eventNames.push({
            name: e,
            selected: false,
          })
        })
      }).catch(() => {
      })
    },

    async loadDevicesList() {
      await this.$api.get('/device/options').then(r => {
        this.deviceList = []
        r.data.forEach(elem => {
          if (elem.name.trim() === '') {
            elem.name = this.$t('no_name')
          }
          elem.displayName = `${elem.ip} - ${elem.name}`
          this.deviceList.push(elem)
        })
      }).catch(() => {
      })
    },
    async resolveEvent(row) {
      if (confirm(this.$t("are_you_sure_for_resolve_event", {id: row.id}))) {
        await this.$api.put(`/component/events/${row.id}/resolve`).then((r) => {
          row.resolved_at = r.data.resolved_at
          row.resolved_by = r.data.resolved_by
          this.$noty.success(this.$t('event_success_resolved', {id: r.data.id}))
        }).catch(() => {

        })
      }
    },
    async resolveAll(btn) {
      if (confirm(this.$t("are_you_sure_for_resolve_all_filtered_events"))) {
        btn.target.setAttribute("disabled", '')
        let data = {}
        data.names = this.filter.names
        data.start = moment(this.filter.range[0]).format("YYYY-MM-DD HH:mm:ss")
        data.end = moment(this.filter.range[1]).format("YYYY-MM-DD HH:mm:ss")
        data.device = this.filter.device
        data.query = this.filter.query
        data.not_resolved = this.filter.only_not_resolved
        data.severity = this.filter.severity

        await this.$api.put(`/component/events/resolve-all`, data).then((r) => {
          this.$noty.success(this.$t('events_success_resolved', {count: r.data.length}))
        }).catch(() => {

        }).finally(() => {
          btn.target.removeAttribute('disabled')
        })
      }
    },
    async getDevice(deviceId) {
      let data = {}
      await this.$api.get('/device/' + deviceId).then(r => {
        r.data.displayName = `${r.data.ip} - ${r.data.name}`
        data = r.data
      }).catch(() => {
      })
      return data
    },
    loadData() {
      console.log("Refresh table")
      this.loading = true
      if (typeof this.$refs.table !== 'undefined') {
        this.$refs.table.refresh()
      }
    }
  }
}
</script>
<style scoped >
.row-severity-INFO {
  color: white;
  text-align: center;
  background: #1b4584;
  padding: 5px;
  border-radius: 7px;
}
.row-severity-WARNING {
  color: white;
  text-align: center;
  background: #b77f20;
  padding: 5px;
  border-radius: 7px;
}
.row-severity-CRITICAL {
  color: white;
  text-align: center;
  background: darkred;
  padding: 5px;
  border-radius: 7px;
}

.row-resolved {
  background: #eeeeee;
  color: #777777;
}

.row-resolved:hover {
  background: #eeeeee !important;
  color: #020202;
}


.mx-input {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:hover {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.mx-input:active {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.a-to-badge:active {
  color: #FAFAFA !important;
}

.a-to-badge:visited {
  color: #FAFAFA !important;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}
</style>