<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-mini col-sm-12">
        <ErrorCounters ref="errorCounters"></ErrorCounters>
        <FullscreenPreloader v-show="preloading"></FullscreenPreloader>
        <Card :name="$t('filters')" :show="true" :disable-hide="true">
          <div class="row">
            <div class="col-xl-4 col-sm-12 col-lg-4 col-md-4">
              <div class="form-group">
                <label class="mb-0">{{ $t('device_groups') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.device_groups"
                          label="displayName"
                          :options="deviceGroups.filter(e => {return filter.device_groups.filter(s => e.id === s.id).length === 0})"
                          :multiple="true"
                          :close-on-select="false"
                />
              </div>
            </div>
            <div class="col-xl-4 col-sm-8 col-lg-4 col-md-4" style="min-width: 330px; ">
              <div class="form-group">
                <label class="mb-0">{{ $t('time_range') }}</label>
                <date-picker v-model="filter.time_range" type="datetime" range :showSecond="false"
                             style="width: 100%"></date-picker>
              </div>
            </div>
            <div class="col-xl-2 col-sm-4 col-lg-2 col-md-2" style=" ">
              <div class="form-group">
                <label class="mb-0">{{ $t('graph_step') }}</label>
                <v-select class="style-chooser"
                          v-model="filter.step"
                          label="displayName"
                          :options="step"
                          :multiple="false"
                          :close-on-select="true"
                          :clearable="false"
                />
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-sm-2 col-md-2">
              <div class="form-group">
                <label class="mb-0"> &nbsp;</label>
                <button class="btn btn-default btn-block btn-sm" style="padding: 2.5px" @click="search">
                  <i
                      class="mdi mdi-table-search mdi-18px"></i></button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div class="col-12 col-mini">
        <Card :name="$t('chart_data')" :show="true" :disable-hide="true">
          <Preloader v-if="chartLoading"></Preloader>
          <line-chart-container @onClick="setDate" v-show="!chartLoading" :height="300" ref="chart" :hide-options="true"
                                :options="chartOptions" :call-back="chartCallBack"></line-chart-container>
          <small>{{ $t('analytics_click_on_chart_for_load_data') }}</small>
        </Card>
        <Card :name="$t('data')" :show="true" :disable-hide="true">
          <div style="float: right; font-weight: bold; padding-top: 5px; font-size: 12pt"><i class="mdi mdi-timelapse"></i>
            {{ moment(filter.choosed_time).format('YYYY-MM-DD HH:mm:ss') }}, {{$t('step')}}: {{this.filter.step}}
          </div>
          <v-server-table :columns="table.columns" :options="table.options" ref="table"
                          @loading="() => {this.preloading = true}" @loaded="() => {this.preloading = false}"
                          class="btn-add-margin-top-for-mobile table-sm">
            <template v-slot:device="{row}">
              <router-link :to="{name: 'device_dashboard', params: {id: row.interface.device.id}}">
                {{ row.interface.device.ip }} <small>({{ row.interface.device.name }})</small>
              </router-link>
            </template>
            <template v-slot:type="{row}">
              {{row.interface.device.model.type}}
            </template>
            <template v-slot:device_group="{row}">
              {{row.interface.device.group.name}}
            </template>
            <template v-slot:interface="{row}">
              <router-link
                  :to="{name: 'device_iface_dashboard', params: {id: row.interface.device.id, 'interface': row.interface.bind_key}}">
                {{ row.interface.name }}
              </router-link>
            </template>
            <template v-slot:chart="{row}">
                <a href="javascript:void(0)" @click="$refs.errorCounters.showChart(row.interface)" ><i class="mdi mdi-chart-areaspline mdi-18px" style="font-size: 10pt"></i> </a>
            </template>

          </v-server-table>
        </Card>
      </div>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';
import Preloader from "@/components/Preloader";
import Card from "@/components/Card"
import FullscreenPreloader from "@/components/FullscreenPreloader.vue";
import LineChartContainer from "@/components/Charts/LineChartContainer.vue";
import DefaultChartOptions from "@/components/Charts/options/line/stackedline";
import ChartCallBack from "@/components/Charts/callback";
import Chart from "chart.js";
import ErrorCounters from "@/components/Modals/ErrorCounters.vue";

var filter = {}

export default {
  components: {
    ErrorCounters,
    LineChartContainer,
    FullscreenPreloader,
    Card,
    Preloader,
    DatePicker,
  },
  data() {
    return {
      data: [],
      filter: {
        time_range: [],
        device_groups: [],
        step: '10m',
        choosed_time: null,
      },
      step: [
        '5m',
        '10m',
        '15m',
        '30m',
        '1h',
        '2h',
        '3h',
      ],
      chartOptions: DefaultChartOptions,
      chartCallBack: (new ChartCallBack()),
      preloading: true,
      chartLoading: true,
      actionQuery: '',
      deviceList: [],
      deviceLoading: false,
      deviceGroups: [],
      table: {
        columns: ['device', 'type', 'device_group', 'interface', 'in_errors', 'out_errors', 'counter_in_errors', 'counter_out_errors', 'chart'],
        options: {
          requestFunction(data) {
            let tm = Math.round(filter.choosed_time.getTime() / 1000)
            data.device_groups = filter.device_groups
            data.step = filter.step
            data.choosed_time = tm
            switch (data.orderBy) {
              case 'device':
                data.orderBy = 'interface.device.ip';
                break;
              case 'interface':
                data.orderBy = 'iface_name';
                break;
            }
            return this.$api.put('/component/analytics/table/increasing-errors', data).catch(function (e) {
              this.dispatch('error', e);
            }).then(resp => {
              resp.count = resp.meta.total_records
              return resp;
            });
          },
          skin: 'VueTables__table table table-striped table-bordered table-hover styled-table',
          perPage: 300,
          perPageValues: [300, 500, 1000],
          headings: {
            'device': this.$t('device'),
            'interface': this.$t('interface'),
            'in_errors': this.$t('increasing_in_errors'),
            'out_errors': this.$t('increasing_out_errors'),
            'counter_in_errors': this.$t('counter_in_errors'),
            'counter_out_errors': this.$t('counter_out_errors'),
            'chart': this.$t('chart'),
            'type': this.$t('type'),
            'device_group': this.$t('device_group'),
          },
          sortable: ['device', 'interface', 'status', 'in_errors', 'out_errors', 'counter_in_errors', 'counter_out_errors',],
          // filterable: false,
          texts: {
            count: this.$t('dt_table.count'),
            first: this.$t('dt_table.first'),
            last: this.$t('dt_table.last'),
            filter: this.$t('dt_table.filter'),
            filterPlaceholder: this.$t('dt_table.filterPlaceholder'),
            limit: this.$t('dt_table.limit'),
            page: this.$t('dt_table.page'),
            noResults: this.$t('dt_table.noResults'),
            filterBy: this.$t('dt_table.filterBy'),
            loading: this.$t('dt_table.loading'),
            defaultOption: this.$t('dt_table.defaultOption'),
            columns: this.$t('dt_table.columns'),
          },
        },
      },
    }
  },
  created() {
    let currDateString = moment().format("YYYY-MM-DD");
    this.filter.time_range = [
      new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
      new Date()
    ]
    this.filter.choosed_time = this.filter.time_range[1]
    filter = this.filter
    console.log(this.filter)
  },
  async mounted() {
    this.$setRouteMeta(this.$t('increasing_errors'))
    this.$api.enableSupportWaiting()
    this.getDevicesGroups()
    await this.$api.waitResponses()
    this.loadChart()
    this.preloading = false
  },
  methods: {
    setDate(date) {
      console.log(date)
      this.filter.choosed_time = moment(date, 'DD.MM.YY hh:mm').toDate()
      this.$refs.table.refresh()
    },
    async search() {
      this.loadChart()
      this.filter.choosed_time = this.filter.time_range[1]
      this.$refs.table.refresh()
    },
    async loadChart() {
      this.chartCallBack.setUri('/component/analytics/charts/increasing-errors')
      console.log(this.filter)
      this.chartCallBack.setParams({
        step: this.filter.step,
        device_groups: this.filter.device_groups,
        start: this.filter.time_range[0].getTime() / 1000,
        end: this.filter.time_range[1].getTime() / 1000,
      })
      this.chartLoading = true
      await this.$refs.chart.load()
      this.chartLoading = false
    },
    async getDevicesGroups() {
      this.deviceGroups = []
      await this.$api.get('/component/analytics/parameters/device-groups').then(r => {
        r.data.forEach(elem => {
          if (elem.name.trim() === '') {
            elem.name = this.$t('no_name')
          }
          elem.displayName = elem.name
          this.deviceGroups.push(elem)
        })
      }).catch(() => {
      })
    },
  }
}
</script>
<style>
.mx-input {
  border-radius: 2px !important;
  border: 1px solid #e9ecef;
}

.mx-input:hover {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.mx-input:active {
  border-radius: 2px !important;
  border: 1px solid #a0a0a0;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.a-to-badge:active {
  color: #FAFAFA !important;
}

.a-to-badge:visited {
  color: #FAFAFA !important;
}

.a-to-badge {
  color: #FAFAFA !important;
}

.VueTables__limit-field label {
  display: inline;
  margin: 5px;
}

.json-tree {
  padding-left: 10px !important;
}

.json-tree-key {
  font-size: 14px;
}

.status-flicker {
  animation: flicker 2s infinite;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border-radius: 50%;

}

.status-online {
  -webkit-box-shadow: 0px 0px 5px 0px darkgreen;
  -moz-box-shadow: 0px 0px 5px 0px darkgreen;
  box-shadow: 0px 0px 5px 0px darkgreen;
  background: darkgreen;
}

.status-offline {
  -webkit-box-shadow: 0px 0px 5px 0px darkred;
  -moz-box-shadow: 0px 0px 5px 0px darkred;
  box-shadow: 0px 0px 5px 0px darkred;
  background: darkred;
}

@keyframes flicker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}
</style>
