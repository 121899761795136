<template>
  <div :class="'group' + getStyleFromStat "  >
    <div class="group-header waves-effect waves-dark" @click="$emit('change-show', !show)">
      <div class="row">
        <div class="col-7" style="font-size: 12pt">
          {{name}}<br>
          <small>{{description}}</small>
        </div>
        <div class="col-4" style="font-size: 13pt; vertical-align: center; margin: auto; text-align: right;">
          <div>{{stat.devices.online}}/{{stat.devices.all}} <i class="mdi mdi-lan-connect"></i></div>
          <div><small>{{stat.interfaces.online}}/{{stat.interfaces.all}}</small> <i class="mdi mdi-ethernet"></i></div>
        </div>
        <div class="col-1" style="vertical-align: center; margin: auto">
          <ArrowSlide style="float: right; font-size: 14pt; " :def="show"/>
        </div>
      </div>
    </div>


    <transition name="slide">
    <div class="group-body" v-show="show" >
      <slot></slot>
    </div>
    </transition>
  </div>
</template>

<script>
import ArrowSlide from "@/components/ArrowSlide";

export default {
  components: {ArrowSlide},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: 'Описание'
    },
    stat: {
      type: Object,
      default: () => {return {devices: {
          all: 0,
          online: 0,
        },
        interfaces: {
          all: 0,
          online: 0,
        }
      }}
    }
  },
  computed: {
    getStyleFromStat() {
       if(!this.$config.isComponentEnabled('pinger')) return ' group-unknown ';
       if(this.stat.devices.all === 0) return  '';
       if(this.stat.devices.online === 0) return ' group-red ';
       if(this.stat.devices.online === this.stat.devices.all) return ' group-green ';
       if(this.stat.devices.online < this.stat.devices.all) return ' group-yellow ';
       return  ''
    }
  },
}
</script>

<style scoped>
.group {
  width: 100%;
  border: 1px solid #DADADA;
  background: rgba(250,250,250,1);
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}
.group-header {
  border: 0 !important;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.group-body {
   background: rgba(0,0,0,0);
   padding-top: 10px;
   padding-left: 5px;
   padding-right: 5px;
}

.group-green .group-header {
  background: rgba(5, 100, 0, 0.8);
  color: #FAFAFA;
}

.group-unknown .group-header {
  background: rgba(200, 200, 200, 0.8);
  color: #0F0F0F;
}
.group-yellow .group-header {
  background: rgba(192,154,0,1);
  color: #FFF;
}
.group-red .group-header {
  background: rgba(130,0,0,0.9);
  color: #FFF;
}
</style>
