export default {
    permissions: {
        group_descriptions: {
            analytics: "Відображати інформацію про всі пристрої, без групових дозволів користувача. Рекомендується дозволяти лише адміністратору",
            web_portal: "Системна інформація, правила глобального пошуку, необхідні для коректної роботи веб-порталу",
            prom_wrapper: "Правила відображення діаграм, як оптична історія, трафік на ONU або інтерфейсі",
        },
        groups: {
            macros: "Компонент: Макроси",
            sensor_devices: "Датчики",
            oxidized: "Бекапи конфігів (oxidized)",
            analytics: "Аналітика",
            live_traffic: "Живий трафік",
            web_portal: "Веб-портал",
            links: "Компонент: З'єднання",
            olts: "OLTs",
            switches: "Switches",
            notifications: "Компонент: Сповіщення",
            events: "Компонент: Події",
            router_os: "Компонент: RouterOS",
            prom_wrapper: "Компонент: Prometheus",
            diag: "Компонент: Діагностика",
            sd: "Компонент: Пошук пристрою",
            fdb_history: "Компонент: FDB історія",
            system: "Система",
            sys_info: "Веб-портал",
            user_management: "Користувачі",
            device_management: "Обладнання",
            switcher_core_dev_control: "Робота з обладнанням",
            switcher_core: "Інформація з обладнання",
            onu_registration: "Модуль: Реєстрація ОНУ на ZTE",
        },
        keys: {
            unregistered_onts_result_output: "Реєстрація ОНУ: Дозволити перегляд консольного виводу при помилці ",
            macros_execute: "Запуск макросів",
            macros_edit: "Налаштування макросів",
            analytics: "Аналітика",
            olts_ctrl_uni_ports: "Управління UNI-портами на ONU",
            sensor_devices_view: "Відображення данних з датчиків",
            sensor_devices_allow_switch_modes: "Дозволити зміну стану датчиків",
            sensor_devices_configure: "Дозволити налаштування датчиків",
            olts_ctrl_port: "Керування фізичними портами",
            device_iface_manage_marks: "Керування обраними/тегами інтерфейсами",
            user_management_config_strict_ip: "Налаштовувати дозволені IP/мережі",
            external_apps_oxidized: "Дозволити перегляд конфігів",
            billing_integration_show_info: "Дозволити дігностику для біллінгів",
            system_cross_auth_user: "!!! Дозволити авторизувати інших користувачів (генерувати ключі)",
            poller_info_by_device: "Poller info on device dashboard",
            device_iface_management: "Device interface management",
            run_poller_by_device: "Run poller on device dashboard",
            analytics_ont_list: "ONT список (детальна поточна інформація)",
            analytics_ont_status_history: "Історія стану ONT",
            analytics_iface_status_history: "Історія статусу Ifaces",
            analytics_device_online_history: "Історія стану пристрою",
            switches_save_config: "Дозволити зберегти конфігурацію",
            switches_vlan_port_control: " Контроль VLAN",
            olts_ctrl_dereg: 'Дозволити скасування ONT',
            olts_ctrl_reboot: 'Дозволити перезавантаження ONT',
            olts_ctrl_clear_counters: 'Дозволити очищення лічильників ONT',
            olts_ctrl_reset: 'Дозволити скидання ONT',
            olts_ctrl_disable: 'Дозволити вимкнути/ввімкнути ONT',
            olts_ctrl_description: 'Дозволити змінювати опис ONT',
            events_configuration: "Налаштувати події",
            system_status: "Статус системи",
            live_traffic_info: "Перегляд живого трафіка",
            dashboard_global_edit: "Глобальне редагування дашборду",
            dashboard_edit: "Редагування дашборду",
            olts_onu_disable: 'Дозволити відключити ONT',
            olts_onu_description: 'Дозволити змінювати опис ONT',
            links_view: "Просмотр з'єднань",
            links_edit: "Редагування з'єднань",
            external_apps_phpmyadmin: "phpMyAdmin",
            events_see_all: "Відображати все події",
            notifications_send_global_notify: "Відправляти глобальні нотифікації (без пристрою)",
            unregistered_onts: "Реєстрація ОНУ",
            unregistered_onts_config: "Конфігурація реєстрації ОНУ",
            notifications_full_access: "Повний доступ",
            notifications_configure_contacts: "Дозволити налаштування контактів",
            notifications_configure_self_contacts: "Дозволити налаштування своїх контактів",
            log_poller: "Логи опитувальника",
            olts_info: "Інфо з ОЛТів",
            olts_onu_reboot: "Дозволити перезавантажувати ОНУ",
            olts_onu_dereg: "Дозволити видалення ОНУ",
            olts_onu_reset: "Дозволити скидання ОНУ",
            olts_onu_clear_counters: "Дозволити очищення лічильників",
            switches_info: "Інфо з свічів",
            switches_reboot_device: "Дозволити перезавантаження",
            switches_clear_counters: "Дозволити очищення лічильників",
            switches_set_port_description: "Змінювати опис порту",
            switches_set_port_admin_state: "Змінювати адмін-стан порту",
            switches_set_port_admin_speed: "Змінювати швидкість порта",
            events_show: "Дозволити відображення подій",
            events_resolve: "Дозволити вирішувати (закривати) події",
            external_apps_grafana: "Grafana",
            external_apps_prometheus: "Prometheus",
            external_apps_alertmanager: "Alertmanager",
            router_os_info: "Отримати інформацію про ОС маршрутизатора",
            prom_chart_info: "Діаграми",
            diag_arp_ping: "ARP пінг",
            diag_icmp_ping: "ICMP пінг",
            diag_traceroute: "Traceroute",
            diag_interface: "Діагностика інтерфейсу (для біллінгу)",
            sd_search_ip_with_port: "Пошук ARP за допомогою FDB (пошук портів)",
            device_groups_management: "Керування групами пристроїв",
            sd_search_mac: "Дозволити пошук за MAC-адресою",
            sd_search_ip: "Дозволити пошук за IP-адресою",
            fdb_history_by_interface: "Історія по інтерфейсу",
            fdb_history_search: "Дозволити пошук історії",
            schedule_reports: "Перегляд звітів планувальника",
            schedule_configuration: "Конфігурація планувальника",
            system_configuration: "Читання / зміна системних налаштувань",
            global_search: "Глобальний пошук",
            system_info: "Доступ до веб-інтерфейсу",
            user_management: "Повний доступ",
            user_self_control: "Оновлення інформації про себе",
            user_display: "Перегляд інформації про інших користувачів",
            device_access_management: "Управління доступами",
            device_show: "Перегляд пристроїв",
            device_management: "Управління пристроями",
            full_sw_core_access: "Повний доступ",
            swc_reboot_onu: "Перезагрузка ОНУ",
            system_logs_actions: "Список дій",
            users_list: "Список користувачів",
            log_switcher_core_actions: "Список виклику обладнання",
            zte_unregistered_onts: "Незареєстрировані ОНУ",
            zte_unregistered_onts_config: "Конфігурування реєстрації ОНУ",
        }
    },
}
