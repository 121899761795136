import defaults from './config/global'
import router from './router'
import App from "./App";
import ServerApi from "./common/http";
import Auth from "./store/auth";
import VueNoty from "./common/noty";
import Vue from 'vue'
import VueRouter from 'vue-router'
import {ClientTable, ServerTable} from 'vue-tables-2-premium';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import '@mdi/font/css/materialdesignicons.min.css';
import VueI18n from 'vue-i18n'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faSort, faSortDown, faSortUp, faUserSecret} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import MySortControl from "@/components/Table/MySortControl";
import VModal from 'vue-js-modal'
import helpers from "./common/helpers";
import moment from 'moment'
import {createPopper} from '@popperjs/core';
import InfiniteLoading from 'vue-infinite-loading';
import VueMobileDetection from "vue-mobile-detection";
import {
    LCircle,
    LCircleMarker,
    LControl,
    LControlLayers,
    LGeoJson,
    LIcon,
    LIconDefault,
    LMap,
    LMarker,
    LTileLayer
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import JsonTree from 'vue-json-tree'
import MyFilter from "./components/Table/MyFilter";
import MyTable from "./components/Table/MyTable";
import LocalConfig from "./store/localConfig";
import 'leaflet-editable';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import InputColorPicker from "vue-native-color-picker";
import {Icon} from 'leaflet';
import { createPinia, PiniaVuePlugin } from 'pinia'
import './registerServiceWorker'

const api = new ServerApi(defaults.apiBaseUrl)
const auth = new Auth(api)
const locCfg = new LocalConfig()
const noty = new VueNoty()


library.add(faUserSecret)
library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSortDown);
let attr = document.getElementById("pon-helper");
let baseUrl = attr.getAttribute('api-base-url');
if(baseUrl !== null) {
    console.log("Set from attr:  api-base-url="+baseUrl)
    defaults.apiBaseUrl = baseUrl
}

if(auth.isLogged()) {
    api.setToken(auth.getAuthKey())
}

api.setNoty(noty)

api.set401callback(()=>{
    noty.info("You session is closed, try re-login", {
        killer : true
    })
    auth.logout()
    window.location.href = "/login"
})
api.set530callback(()=>{
    let page = window.location.href
    console.log(page)
    if (!page.includes("/login")) {
        auth.logout()
        window.location.href = "/login?error=agent_disabled"
    }
})

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

router.beforeEach((to, from, next) => {

    if(from.query._iframe) {
        to.query._iframe='yes'
    }
    if(from.query._show_wdms_btn) {
        to.query._show_wdms_btn='yes'
    }

    if(to.matched.some(record => typeof record.meta.allowAnonymous === 'undefined' || !record.meta.allowAnonymous )) {
        if (auth.isLogged() || to.query.WCA_AUTH_KEY) {
            next()
            return
        }
         next('/login?redirect=' + to.path)
    } else {
        next()
    }
})

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VueMobileDetection);
Vue.use(VueRouter)
Vue.use(InputColorPicker)
Vue.use(ClientTable, {}, false, 'bootstrap4',  {
    sortControl: MySortControl,
    genericFilter: MyFilter,
    dataTable: MyTable,
})

Vue.use(ServerTable, {limit: 50}, false, 'bootstrap4',  {
    sortControl: MySortControl,
    genericFilter: MyFilter,
    dataTable: MyTable,
})
Vue.use(VueI18n)
Vue.use(VModal, {dialog: true})
const i18n = new VueI18n({
    locale: 'en', // установка локализации по умолчанию
    messages: defaults.locale.locales,
    //silentTranslationWarn: true,
})
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

helpers.setI18N(i18n)
api.setI18N(i18n)

Vue.component('json-tree', JsonTree)
Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('l-control', LControl);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-icon-default', LIconDefault);
Vue.component('l-circle-marker', LCircleMarker);
Vue.component('l-circle', LCircle);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('../public/assets/map/icon48.png'),
    iconUrl: require('../public/assets/map/icon48.png'),
    shadowUrl: null,
    shadowAnchor: null,
    shadowSize:   null,
    iconSize: [24, 24],
    iconAnchor: [13, 13],
    popupAnchor:  [0, 0]
});

Vue.prototype.$createPopper = createPopper
Vue.prototype.$config = defaults
Vue.prototype.$noty = noty
Vue.prototype.$api = api
Vue.prototype.$auth = auth
Vue.prototype.$localCfg = locCfg
Vue.prototype.$helpers = helpers
Vue.prototype.moment = moment

const app = new Vue({
    router,
    i18n,
    render: h => h(App),
    pinia,
});

const meta = Vue.observable({ routeMeta: {} })

Object.defineProperty(Vue.prototype, '$routeMeta', {
    get () {
        return meta.routeMeta
    },
    set (value) {
        meta.routeMeta = value
    }
})


Vue.prototype.$setRouteMeta = function (title, params = {}) {
    Vue.prototype.$routeMeta = {
        title: title,
        params: params,
    }
    if (title) {
        document.title =  title.trim() + " | DMS"
    } else {
        document.title = "WildcoreDMS"
    }
}
var loadProps = async function() {
    await api.get('/public/defaults', {}, true).then( r => {
        i18n.locale = r.data.locales.default
        defaults.name = r.data.app_name
        defaults.isWhiteLabel = r.data?.is_whitelabel ?? false
        defaults.isDemoMode = r.data?.is_demo ?? false
        defaults.locale.supportedLocales = r.data.locales.locales
        document.getElementById('preloader-web-portal').style = 'display: none;'
        app.$mount('#pon-helper')

        if(defaults.isWhiteLabel) {
            for(const locale in defaults.locale.localesWL) {
                const msgs = defaults.locale.localesWL[locale]

                for(const x in msgs) {
                    if(msgs[x] instanceof Object) {
                        defaults.locale.locales[locale][x] = {
                            ...defaults.locale.locales[locale][x],
                            ...msgs[x],
                        }
                    } else {
                        defaults.locale.locales[locale][x] = msgs[x];
                    }
                }
            }
            for(const locale in defaults.locale.locales) {
                app.$i18n.setLocaleMessage(locale, {
                    ...defaults.locale.locales[locale],
                })
            }
        } else {
            document.title = 'WildcoreDMS';
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = '/assets/images/favicon.png';
        }
    }).catch(e => {
        document.getElementById('preloader-web-portal').style = 'display: none;'
        if( e.response?.data?.error){
            document.getElementById('error-loading-portal').innerHTML = `
                <div style="background: #FAFAFA;
    height: 100vh;
    width: 100%;
    padding-top: 100px;
    text-align: center; color: darkred;">
                <h2 style="">
                ${e.response.data.error.description}<br><br>
                <i class="mdi mdi-emoticon-dead" style="font-size: 86px"></i>
                </h2>
                </div>
            `
        } else {
            document.getElementById('error-loading-portal').innerHTML = `
                <div style="background: #FAFAFA;
    height: 100vh;
    width: 100%;
    padding-top: 100px;
    text-align: center; color: darkred;">
                <h2 style="margin-top: 50px; text-align: center">
                ${e.response.data}<br><br>
                        <small>Error get information from API. Please, check logs in server</small></h2>
                        </div>
            `
        }
        throw e
    })
}

loadProps()
