<template>
  <div ref="mainBlock">
    <div id="submenu-tooltip" role="tooltip" ref="submenu" v-click-outside="hideSubMenu">
      <router-link v-if="$auth.isPermitted('pon_boxes_view') && device.model.type === 'OLT'"
                   :title="$t('pon_boxes.name')"
                   class="submenu-link" :to="{name: 'pon_boxes_boxes', query: {device_id: device.id}}">
        <div style="width: 30px"><i class="mdi mdi-family-tree"></i></div>
        <div>{{ $t('pon_boxes.name') }}</div>
      </router-link>
      <router-link v-if="$auth.isPermitted('log_switcher_core_actions')" :title="$t('log_device_calling')"
                   class="submenu-link" :to="{name: 'log_device_calling_full', query: {device_id: device.id}}">
        <div style="width: 30px"><i class="fa fa-code"></i></div>
        <div>{{ $t('log_device_calling') }}</div>
      </router-link>
      <router-link v-if="$auth.isPermitted('device_management')" :title="$t('edit')" class="submenu-link"
                   :to="{name: 'management_device_edit', params: {id: device.id}}">
        <div style="width: 30px"><i class="fa fa-edit"></i></div>
        <div>{{ $t('edit') }}</div>
      </router-link>
      <a target="_blank" v-if="$auth.isPermitted('device_management')" :title="$t('web')" class="submenu-link"
         :href="'http://' + device.ip"
      >
        <div style="width: 30px; margin-left: -3px; margin-top: -5px; margin-bottom: -5px;"><i style=""
                                                                                               class="mdi mdi-web mdi-18px"></i>
        </div>
        <div>{{ $t('web') }}</div>
      </a>
      <a target="_blank" v-if="$auth.isPermitted('device_management')" :title="$t('ssh')" class="submenu-link"
         :href="'ssh://' + device.ip"
      >
        <div style="width: 30px; margin-left: -3px; margin-top: -5px; margin-bottom: -5px;"><i
            class="mdi mdi-lock  mdi-18px"></i></div>
        <div>{{ $t('ssh') }}</div>
      </a>
      <a target="_blank" v-if="$auth.isPermitted('device_management')" :title="$t('telnet')" class="submenu-link"
         :href="'telnet://' + device.ip"
      >
        <div style="width: 30px; margin-left: -3px; margin-top: -5px; margin-bottom: -5px;"><i
            class="mdi mdi-console  mdi-18px"></i></div>
        <div>{{ $t('telnet') }}</div>
      </a>
    </div>
    <router-link style="color: black" :to="{ name: 'device_dashboard', params: { id: device.id }}">
      <div
          style="position: absolute; top: 0px; right: 10px; height: 70px; width: 60px; padding-left: 25px;   z-index: 9"
          v-on:click.prevent>
        <a href="javascript:void(0)" class="submenu" id="submenu-btn" @click="toggleSubMenu"
           style="font-size: 24px; padding-bottom: 0px; padding-left: 3px; padding-right: 3px"><i
            class="mdi mdi-menu"></i></a>
        <div style="margin-top: -7px">
          <i class="mdi mdi-lan-disconnect" title="Device if down over ICMP"
             style="color: darkred; font-size: 22px; padding-left: 3px; padding-right: 3px"
             v-if="device.pinger !== null && device.pinger.latency <= 0"></i>
          <i class="mdi mdi-lan-disconnect" title="No information from ICMP pinger"
             style="color: gray; font-size: 22px; padding-left: 3px; padding-right: 3px"
             v-if="device.pinger === null"></i>
        </div>
      </div>
      <div :class="getClassesByPinger(device)" style="padding: 5px">
        <div class="row">
          <div class="col-3" style="margin: 0; padding: 0">
            <img :src="device.model.icon"
                 style="max-width: 100%; max-height: 70px;  margin-bottom: 5px; padding-left: 10px; padding-right: 5px">
          </div>
          <div class="col-7" style="font-weight: bold; margin: 0; padding: 0">
            <div style="font-size: 90%"><i class="mdi mdi-server-network"></i>{{ device.model.name }}</div>
            <div class=""><i class="mdi mdi-network"></i> {{ device.ip }}</div>
            <div class=""
                 v-if="$auth.getSettings()?.device?.interfaces_stat_type === 'all' && device.ifaces_stat !== null && device.ifaces_stat.up + device.ifaces_stat.down !== 0"
                 :title="device.ifaces_stat.last_stat"><i class="mdi mdi-ethernet"></i> <span style="color: darkgreen">{{
                device.ifaces_stat.up
              }}</span>/<span style="color: darkred">{{
                device.ifaces_stat.down
              }}</span>/{{ device.ifaces_stat.down + device.ifaces_stat.up }}
            </div>
            <div v-if="$auth.getSettings()?.device?.interfaces_stat_type === 'splitted' && device.ifaces_stat && device.ifaces_stat.splitted != null" style="display: inline">
              <div style="float: left; padding-right: 10px" v-for="(data, k) in device.ifaces_stat.splitted" :key="k" :title="k" v-show="data.down + data.up > 0">
                <i :class="`mdi ` + (k === 'PHYSICAL' ? 'mdi-ethernet' : 'mdi-router-wireless')" style="padding-right: 2px"></i>
                <span style="color: darkgreen">{{ data.up }}</span>/<span style="color: darkred">{{data.down}}</span>/{{ data.down + data.up }}
              </div>
            </div>
          </div>
        </div>
        <div :style="'margin-top: 0; margin-right: 30px'   ">{{ device.name }}</div>
      </div>
    </router-link>
  </div>
</template>


<script>
export default {
  props: {
    device: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      submenu: false,
    }
  },
  methods: {
    toggleSubMenu(event) {
      if (this.submenu) {
        this.hideSubMenu(null)
      } else {
        this.showSubmenu(event)
      }
    },
    hideSubMenu(event) {
      if (event === null) {
        if (this.$refs.submenu.hasAttribute('data-show')) {
          this.$refs.submenu.removeAttribute('data-show');
        }
        this.submenu = false
        return;
      }

      const eventPath = event.path || (event.composedPath && event.composedPath());

      if (eventPath.some(e => e.id === 'submenu-tooltip' || e.id === 'submenu-btn' || typeof e.id !== 'undefined' && e.id !== null)) {
        return;
      } else {
        this.$refs.mobileFilter.removeAttribute('data-show');
      }

      if (this.$refs.submenu.hasAttribute('data-show')) {
        event.preventDefault()
        this.$refs.submenu.removeAttribute('data-show');
      }
      this.submenu = false
    },
    showSubmenu(event) {
      this.submenu = true
      let submenu = this.$createPopper(event.target, this.$refs.submenu, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
              altBoundary: false,
              flipVariations: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [-95, 4],
            },
          },
        ],
      });
      this.$refs.submenu.setAttribute('data-show', '');
    },
    getClassesByPinger(device) {
      if (device === null) {
        return ''
      }
      if (device.pinger === null) {
        return 'device-unknown'
      }
      if (device.pinger.latency <= 0) {
        return 'device-offline'
      } else {
        return 'device-online'
      }
    },
  }
}
</script>
<style>

.submenu-link {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #DADADA;
  border-collapse: collapse;
  margin-bottom: -1px;
  color: #2c2c2c;
  width: 200px;
}

.submenu-link:hover {
  background-color: #27a8e2;
  color: white;
}

.submenu-link:active {
  background-color: #186183;
  color: white;
}

.btn-submenu {
  width: 100%;
  height: 100% !important;
  border-radius: 0;
  padding: 0;
}

.no-padding {
  padding: 0 !important;
}

.padding {
  padding-left: 10px;
  padding-right: 10px;
}

#submenu-tooltip {
  background: #FAFAFA;
  color: black;
  font-size: 13px;
  border-radius: 0px !important;
  display: none;
  margin-left: 10px !important;
  margin-right: 10px !important;
  z-index: 100;
  -webkit-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 0px 14px 0px rgba(0, 0, 0, 0.75);
}

#submenu-tooltip[data-show] {
  display: block;
}

#submenu-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#submenu-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: 4px;
}

#submenu-tooltip[data-popper-placement^='left'] > #arrow {
  right: 4px;
}

#submenu-tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
